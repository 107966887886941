import React, { useEffect, useState } from "react";
import "./AssessmentsScore.css";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Line } from "react-chartjs-2";
import { formatDataset } from "./helper/formatDataset";
import { formatLabels } from "./helper/formatLabels";
import { externalTooltipHandler } from "./helper/externalToolTipHandler";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { BsChevronRight } from "react-icons/bs";
import { add, format, parseISO } from "date-fns";
// import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import ASSETS from "../../../../../../../assets/Assets";

const AssessmentSelector = ({ assessment, handleAssessmentChange }) => {
  return (
    <div className="assessment-selector">
      <FormControl sx={{ m: 1, minWidth: 200 }} id="form-control">
        <InputLabel
          id="assessment-selector-label"
          sx={{ fontFamily: "Poppins", color: "#282860", fontSize: "12px" }}
        >
          Assessment
        </InputLabel>
        <Select
          labelId="assessment-selector-label"
          id="choose-assessment-selector"
          value={assessment}
          label="Assessment"
          onChange={handleAssessmentChange}
          sx={{
            background: "rgba(221, 226, 233, 0.15)",
            outline: "none",
            border: "none",
            borderRadius: "8px",
          }}
        >
          <MenuItem value={"PHQ9"}>PHQ 9</MenuItem>
          <MenuItem value={"GAD7"}>GAD 7</MenuItem>
          <MenuItem value={"AI CHECK IN"}>
            Ai Check in Status
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const ScoreGraph = ({ data = [], assessment }) => {
  const oneDataPoint = data[0];

  const [dataSet, setDataSet] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const dataset = formatDataset(data);
    setDataSet(dataset);

    const labels = formatLabels(data);
    setLabels(labels);
  }, [data]);

  // onClick={() => showAssessmentDetailCallback(assessment)}
  const onClickDataPointHandler = (event) => {
    console.log(event);
  };

  return (
    <div className="assessment-score-graph">
      <Line
        style={{ background: "white", zIndex: 1000 }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          showLine: true,
          //onClick: onClickDataPointHandler,
          layout: {
            padding: {
              // left: 40,
              // bottom: 20,
            },
          },
          elements: {
            point: {
              borderColor: "#000",
              borderWidth: 1,
              radius: 4,
            },
          },
          scales: {
            y: {
              // Maximum is set to 7 to adjust the height of individual points according to y-axis legends
              // max: 30,
              max: assessment === "AI CHECK IN" ? 100 : 30,
              min: 0,
              beginAtZero: true,
              grid: { display: false },
              ticks: { display: true, padding: 15, stepSize: 6 },
            },
            x: {
              beginAtZero: false,
              ticks: {
                //stepSize: 30,
                // callback: function (value, index, ticks) {
                //   const t = secondsToHHMMSS(value);
                //   return t;
                // },
              },
              grid: { display: false },
              offset: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              position: "nearest",
              //external: externalTooltipHandler,
            },
          },
        }}
        data={{ labels, datasets: dataSet }}
      // plugins={[
      //   {
      //     afterDraw: (chart) => {
      //       console.log(chart);
      //       var ctx = chart.ctx;
      //       var xAxis = chart.scales["x"];
      //       var yAxis = chart.scales["y"];
      //       yAxis.ticks.forEach((value, index) => {
      //         console.log(value.value);
      //         var y = yAxis.getPixelForTick(index);
      //         var image = new Image();
      //         image.src = triggerMapImage[value.value];
      //         ctx.drawImage(image, 6, y - 12);
      //       });
      //     },
      //   },
      // ]}
      />
    </div>
  );
};

const convertToLocalTimezone = (timestamp) => {
  let t = timestamp.split(/[- :]/);

  // Apply each element to the Date function
  let d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

  return d;
};

const AssessmentItem = ({ assessment, showAssessmentDetailCallback }) => {
  const showRed = assessment["score"] > assessment["maximum_score"] / 2;

  return (
    <div
      className="assessment-item-wrapper"
      onClick={() => showAssessmentDetailCallback(assessment)}
    >
      <div className="assessment-item-date-time-wrapper">
        <div className="assessment-item-date">
          {new Date(assessment["completed_at"]).toString().slice(4, 16)}
        </div>
        <div className="assessment-item-time">
          {convertToLocalTimezone(
            assessment["completed_at"]
          ).toLocaleTimeString()}
        </div>
      </div>
      <div className="assessment-item-score-wrapper">
        <span
          className="assessment-item-score"
          style={showRed ? { color: "rgba(236, 116, 64, 1)" } : {}}
        >
          {assessment["score"]}
        </span>
        <span className="assessment-item-maximum-score">
          /{assessment["maximum_score"]}
        </span>
      </div>
      <div className="assessment-item-summary-wrapper"></div>
      <div className="assessment-item-edit-icon-wrapper">
        <div className="assessment-item-edit-icon">
          <img src={ASSETS.pageEditIcon} alt="page-edit" />
        </div>
        <div className="assessment-item-left-arrow">
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
};

const AssessmentList = ({ data = [], showAssessmentDetailCallback }) => {
  const reversedData = [...data].reverse();

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {reversedData.map((assessment) => (
        <TimelineItem key={assessment["assessment_id"]}>
          <TimelineSeparator>
            <TimelineDot style={{ background: "#5F6CE1" }} />
            <TimelineConnector style={{ background: "#5F6CE1" }} />
          </TimelineSeparator>
          <TimelineContent>
            <AssessmentItem
              assessment={assessment}
              showAssessmentDetailCallback={showAssessmentDetailCallback}
            />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const AssessmentsScore = ({
  assessmentData = [],
  showAssessmentDetailCallback,
}) => {
  const [assessment, setAssessment] = React.useState("PHQ9");
  const handleAssessmentChange = (event) => {
    setAssessment(event.target.value);
  };
  const [filteredAssessmentData, setFilteredAssessmentData] = useState([]);

  useEffect(() => {
    //Filtering
    const filteredData = assessmentData.filter(
      (item) => item["assessment_type"] === assessment && item["score"] !== -1
    );

    //Sorting
    const sortedData = filteredData.sort(function (a, b) {
      return new Date(a.completed_at) - new Date(b.completed_at);
    });
    setFilteredAssessmentData(sortedData);
  }, [assessment]);

  return (
    <div className="assessment-score-container">
      <div className="assessment-score-container-selector">
        <div>Score Progression</div>
        <AssessmentSelector
          assessment={assessment}
          handleAssessmentChange={handleAssessmentChange}
        />
      </div>
      <ScoreGraph
        data={filteredAssessmentData}
        assessment={assessment}
      />
      <AssessmentList
        data={filteredAssessmentData}
        showAssessmentDetailCallback={showAssessmentDetailCallback}
      />
    </div>
  );
};

export default AssessmentsScore;
