import React from "react";
import "./AssessmentSummary.css";

const AssessmentSummary = ({ summaryData }) => {
  return (
    <div className="assessment-summary">
      <div className="assessment-summary-heading">Summary</div>
      <div className="assessment-summary-vertical-line"></div>
      <div className="assessment-summary-completed">
        {summaryData["completed"]}{" "}
        <span className="assessment-summary-text">Completed</span>
      </div>
      <div className="assessment-summary-phq9">
        {summaryData["phq9"]}{" "}
        <span className="assessment-summary-text">PHQ9</span>
      </div>
      <div className="assessment-summary-gad7">
        {summaryData["gad7"]}{" "}
        <span className="assessment-summary-text">GAD7</span>
      </div>
      <div className="assessment-summary-ai-check-in">
        {summaryData["aiCheckIn"]} {" "}
        <span className="assessment-summary-text">Ai Check-in Status</span>
      </div>
      <div className="assessment-summary-missed">
        {summaryData["missed"]}{" "}
        <span className="assessment-summary-text">Missed</span>
      </div>
    </div>
  );
};

export default AssessmentSummary;
