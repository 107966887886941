const progressBar = {
  height: "1.5rem",
  backgroundColor: "#2c3254",
  borderRadius: "0.2rem",
  // border: "1.446px solid rgba(44, 50, 84, 0.35)",
  background: "rgba(175, 175, 175, 0.35)",

  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const progressStyle = {
  borderRadius: "0.2rem",
  // border: "1.446px solid rgba(44, 50, 84, 0.15)",
  height: "100%",
};

const ProgressBar = ({ progress, barColor }) => {
  return (
    <div style={progressBar}>
      <div
        style={{
          width: progress + "%",
          display: progress === 0 ? "none" : "",
          background: barColor ? barColor : "#7768FF",
          ...progressStyle,
        }}
      ></div>
      {progress > 10 ? (
        <span
          style={{
            marginLeft: "-46px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {progress}%
        </span>
      ) : (
        <span
          style={{
            marginLeft: "5px",
            color: "#5F6CE1",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {progress}%
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
