import { fetchRealtimeHealthStatusAndTags } from "../../../../api/realtime";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const textStyleHeading = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.02em",
  textAlign: "left",
  color: "#282860",
};

const textStyleBody = {
  ...textStyleHeading,
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.0125em",
};

const flexWrap = {
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
};

const tagChipStyle = {
  borderRadius: "6px",
  opacity: "0.5px",
  background: "rgba(171, 178, 242, 0.35)",
  border: "1px solid #5F6CE1",
  padding: "4px 10px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "18px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "rgba(95, 108, 225, 1)",
};

const TagsView = ({ tags }) => {
  return (
    <div style={{ ...flexWrap }}>
      {tags.map((tag, index) => {
        return (
          <div key={index} style={{ ...tagChipStyle }}>
            {tag}
          </div>
        );
      })}
    </div>
  );
};

function CurrentMentalHealthStatus() {
  const queryParameters = new URLSearchParams(window.location.search);
  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  const therapy_id = queryParameters.get("therapy_id");

  const [tags, setTags] = useState([]);
  const [mentalHealthStatus, setMentalHealthStatus] = useState("");

  useEffect(() => {
    fetchRealtimeHealthStatusAndTags(therapy_id, therapist_id)
      .then((res) => {
        setTags(JSON.parse(res.assessment_suggestions).map((tag) => tag.topic_name));
        setMentalHealthStatus(res.assessment);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setMentalHealthStatus("Please complete your first Realtime Session");
      });
  }, []);

  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{
          ...textStyleHeading,
        }}
      >
        Current Mental Health Status
      </div>
      <p style={{ ...textStyleBody, opacity: 0.8 }}>{mentalHealthStatus}</p>
      <TagsView tags={tags} />
    </div>
  );
}

export default CurrentMentalHealthStatus;
