import ASSETS from "../../../../../assets/Assets";
import "./overviewTabPanel.css";
import { format } from "date-fns";
import PinnedFiles from "../../../../report/components/pinnedFiles/PinnedFiles";
import { LatestActivity } from "../../../../report/components/overview/Overview";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  deleteTaskById,
  getAllTherapyTasksByTherapyId,
  getLatestTherapyTasksByTherapyId,
  getTherapyTasksByTherapyId,
  updateTaskStatusById,
} from "../../../../../api/therapist";
import { useSearchParams } from "react-router-dom";
import { fetchAllActivitiesAPI } from "../../../../../api/activities";
import { setActivities } from "../../../../../store/activities/activitySlice";
import { Task } from "../../../../report/components/tasks/Tasks";
import { getTherapyFilesAPI } from "../../../../../api/therapy";
import { setFiles } from "../../../../../store/files/filesSlice";
import OverallWellbeingMap from "../../../../report/components/overall-wellbeing-map/OverallWellbeingMap";
import CurrentMentalHealthStatus from "../../../../report/components/overview/CurrentMentalHealthStatus";
import ActionSuggestions from "../../../../report/components/overview/ActionSuggestions";

const RecentTaskActivities = function (props) {
  const queryParameters = new URLSearchParams(window.location.search);
  const patient_id = queryParameters.get("patient_id");
  const therapy_id = queryParameters.get("therapy_id");
  const therapist = useSelector((store) => store.therapist);

  let [tasks, setTasks] = useState([]);

  useEffect(() => {
    getAllTherapyTasksByTherapyId(therapy_id).then((res) => {
      let all_tasks = res.all_therapy_tasks.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setTasks(all_tasks);
    });
  }, []);

  function onDeleteButtonClick(idx) {
    deleteTaskById(tasks[idx].task_id, therapist.name).then((res) => {
      setTasks((prevTasks) => [
        ...prevTasks.slice(0, idx),
        ...prevTasks.slice(idx + 1),
      ]);
    });
  }

  return (
    <div className="tasks-container">
      <div className="row" style={{ height: "2rem" }}>
        <div className="tasks-subheading">Recent Tasks & Advices</div>
        <div className="tasks-show-all" onClick={() => props.updateTabIndex(2)}>
          Show All
        </div>
      </div>
      <div className="report-tasks-list">
        {tasks.slice(0, 3).map((task, idx) => {
          return (
            <Task
              key={idx}
              idx={idx}
              task={task}
              onDeleteButtonClick={onDeleteButtonClick}
            />
          );
        })}
      </div>
    </div>
  );
};

const OverviewTabPanel = function (props) {
  const activities = useSelector((store) => store.activities);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const therapy_id = searchParams.get("therapy_id");

  const fetchAllActivities = async () => {
    const data = await fetchAllActivitiesAPI(therapy_id);
    dispatch(setActivities(data));
  };
  useEffect(() => {
    fetchAllActivities();
    getTherapyFilesAPI(therapy_id).then((res) => {
      const files = res.data.map((file) => {
        return {
          fileId: file.file_id,
          filename: file.filename,
          uploaded_at: file.uploaded_at_iso,
          fileUrl: file.file_url,
          size: file.size,
          author: file.author_profile_url,
          pinned: file.pinned !== 0,
        };
      });
      dispatch(setFiles(files));
    });
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="overview-panel-container">
      <div className="overview-left-section">
        <CurrentMentalHealthStatus />
        <OverallWellbeingMap />
        {/* <RecentTaskActivities updateTabIndex={props.updateTabIndex} />
        <PinnedFiles heading={"Pinned Files"} showAllBtn={true} /> */}
        <LatestActivity activity={activities} />
      </div>
      <div className="overview-right-section">
        <ActionSuggestions />
      </div>
    </div>
  );
};

export default OverviewTabPanel;
