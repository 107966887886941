import "./Session.css";

import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import ASSETS from "../../../../assets/Assets";
import TriggerMap from "../triggerMap/TriggerMap";
import EditSessionSummaryModal from "../editSessionSummaryModal/EditSessionSummaryModal";
import {
  fetchMeetingPatientEmotionsAPI,
  fetchMeetingSummaryAPI,
} from "../../../../api/meetingAIAnalysis";
import SignificantTriggerPoints from "./SignificantTriggerPoints";
import TriggerMapNotes from "./TriggerMapNotes";
import Backdrop from "@mui/material/Backdrop";
import DarkOverlay from "./DarkOverlay";
import { createTriggerMapNoteAPI } from "../../../../api/triggerMapNotes";
import { MenuItem, Select } from "@mui/material";

import TherapistReport from "../therapistReport/TherapistReport";
import { getTherapistReport } from "../../../../api/therapist";
import SOAPTabs from "./SOAP/SOAPTabs";

const formatNextSessionPlanning = (sessionPlanning) => {
  let formattedSessionPlanning = sessionPlanning.slice(22).split("\\n");

  return formattedSessionPlanning;
};

function MeetingSessionDetails({ setIndex, videoId, currentSession }) {
  const [showEditSessionSummaryModal, setShowEditSessionSummaryModal] =
    useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const [videoDuration, setVideoDuration] = useState(0);
  const videoPlayerRef = useRef();

  const [videoURL, setVideoURL] = useState("");

  const [meetingSummary, setMeetingSummary] = useState("");
  const [subjective, setSubjective] = useState("");
  const [objective, setObjective] = useState("");
  const [assessment, setAssessment] = useState("");
  const [plan, setPlan] = useState("");

  const [nextMeetingPlanning, setNextMeetingPlanning] = useState([""]);
  const [graphData, setGraphData] = useState([]);
  const [baseVideoURL, setBaseVideoURL] = useState("");
  const [timestamp, setTimestamp] = useState(0);
  const [triggerMapNotes, setTriggerMapNotes] = useState([]);

  const [selectingPoints, setSelectingPoints] = useState(false);
  const [therapistReport, setTherapistReport] = useState([]);

  const handleTimestampSelected = (timestamp) => {
    createTriggerMapNoteAPI(videoId, "", timestamp).then((data) => {
      const newNote = {
        note_id: data.note_id,
        note: data.note,
        timestamp: data.timestamp,
        video_id: data.video_id,
      };
      setTriggerMapNotes((prevNotes) => {
        let newNotes = [newNote, ...prevNotes];
        return newNotes;
      });
      setSelectingPoints(false);
    });
  };

  const handleAddNotesButtonClicked = () => {
    setSelectingPoints(true);
  };

  const updateVideoURL = (timestamp) => {
    setTimestamp(timestamp);
    videoPlayerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setVideoURL(baseVideoURL + "#t=" + timestamp);
  }, [timestamp]);

  useEffect(() => {
    if (videoId) {
      console.log("HI");
      fetchMeetingSummaryAPI(videoId).then((res) => {
        console.log("meeting summary", res);
        setVideoURL(res["video_url"]);
        setBaseVideoURL(res["video_url"]);

        setMeetingSummary(res["summary"].split("Next Meeting Planning:")[0]);
        setSubjective(res["subjective"]);
        setObjective(res["objective"]);
        setAssessment(res["assessment"]);
        setPlan(res["plan"]);
        // const nextSessionPlanning = formatNextSessionPlanning(
        //   res["next_session_planning"]
        // );

        // setNextMeetingPlanning(nextSessionPlanning);
      });
      fetchMeetingPatientEmotionsAPI(videoId).then((res) => {
        setGraphData(res);
      });
      getTherapistReport(videoId).then((res) => {
        // 5905b8d5-c01d-49bb-8e2c-828b540fc1aa  this videoId has some data
        setTherapistReport(res.data);
      });
    }
  }, [videoId]);

  const handleLoadedMetadata = () => {
    setVideoDuration(parseInt(videoPlayerRef.current.duration));
  };

  const changeMeetingSOAPSummary = ({
    summary,
    subjective,
    objective,
    assessment,
    plan,
  }) => {
    console.log(summary);
    setMeetingSummary(summary);
    setSubjective(subjective);
    setObjective(objective);
    setAssessment(assessment);
    setPlan(plan);
  };

  const triggerMapRef = useRef();
  const [triggerMapHeight, setTriggerMapHeight] = useState("20rem");
  useEffect(() => {
    setTriggerMapHeight(triggerMapRef.current.clientHeight);
  }, []);

  const [currentPage, setCurrentPage] = useState("Client");
  const handlePageChange = (event) => {
    setCurrentPage(event.target.value);
  };

  return (
    <>
      <DarkOverlay
        show={selectingPoints}
        close={() => setSelectingPoints(false)}
      />
      <EditSessionSummaryModal
        videoId={videoId}
        summary={meetingSummary}
        subjective={subjective}
        objective={objective}
        assessment={assessment}
        plan={plan}
        changeMeetingSOAPSummary={changeMeetingSOAPSummary}
        show={showEditSessionSummaryModal}
        onClose={(e) => setShowEditSessionSummaryModal(false)}
      />
      <div className="report-session-container">
        <div className="report-session-header row">
          <div className="row justify-flex-start">
            <div
              onClick={() => setIndex(0)}
              className="report-session-back-btn"
            >
              <img src={ASSETS.circularBackBtn} alt="circular back btn" />
            </div>
            <div className="report-session-heading">All Sessions</div>
          </div>
          <div>
            <div
              className="row justify-flex-start"
              style={{ alignItems: "center", gap: "1rem" }}
            >
              <div>Report For:</div>
              <Select value={currentPage} onChange={handlePageChange}>
                <MenuItem value={"Client"}>Client</MenuItem>
                <MenuItem value={"Therapist"}>Therapist</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        {currentPage === "Therapist" && (
          <TherapistReport data={therapistReport} videoId={videoId} />
        )}
        {currentPage === "Client" && (
          <div className="report-session-video-and-summary">
            <div className="session-video">
              <video
                ref={videoPlayerRef}
                src={videoURL}
                alt="session"
                controls
                onLoadedMetadata={handleLoadedMetadata}
              />
            </div>
            <div className="session-summary">
              <div className="session-summary-header row justify-flex-start">
                <div className="session-summary-text">Session Summary</div>
                <img
                  src={ASSETS.pencil}
                  alt="pencil"
                  onClick={(e) => setShowEditSessionSummaryModal(true)}
                />
              </div>

              {/* <div className="session-summary-container">{meetingSummary}</div> */}
              <div
                className="session-summary-container"
                style={{
                  padding: "0.5rem",
                  overflow: "hidden",
                  paddingRight: "0px",
                }}
              >
                <SOAPTabs
                  soap_data={{
                    meetingSummary,
                    subjective,
                    objective,
                    assessment,
                    plan,
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {currentPage === "Client" && (
          <div className="session-trigger-notes-and-trigger-map">
            <div className="trigger-maps-wrapper" ref={triggerMapRef}>
              <div className="session-trigger-map-header row justify-flex-start">
                <div className="session-trigger-map-heading">Trigger Map</div>
                <img
                  src={ASSETS.infoOutline}
                  alt="info outline"
                  className="cursor-pointer"
                  onMouseOver={(e) => setShowInfoPopup(true)}
                  onMouseLeave={(e) => setShowInfoPopup(false)}
                />

                {showInfoPopup && (
                  <div
                    className="session-trigger-map-info-popup"
                    style={{ zIndex: 20000 }}
                  >
                    This is a visual representation of the emotion patterns of
                    the client during a session. This map helps point out any
                    sudden spikes in the emotion to compare with positive and
                    negative transcript and understand the clients behavioural
                    pattern.
                  </div>
                )}
              </div>

              <TriggerMap
                data={graphData}
                updateVideoURL={updateVideoURL}
                session={currentSession}
                selectingPoints={selectingPoints}
                handlePointClicked={handleTimestampSelected}
              />
            </div>
            <TriggerMapNotes
              session={currentSession}
              video_id={videoId}
              handleAddNotesButtonClicked={handleAddNotesButtonClicked}
              triggerMapNotes={triggerMapNotes}
              setTriggerMapNotes={setTriggerMapNotes}
              triggerMapHeight={triggerMapHeight}
            />
          </div>
        )}

        {currentPage === "Client" && (
          <div className="significant-trigger-points">
            <div className="significant-trigger-points-header">
              Overall trigger map report
            </div>
            <div className="significant-trigger-points-wrapper">
              <SignificantTriggerPoints
                videoId={videoId}
                session={currentSession}
                updateVideoURL={updateVideoURL}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MeetingSessionDetails;
