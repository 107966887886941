import React from "react";
import "./SessionItem.css";
import ASSETS from "../../../../assets/Assets";
import { BsChevronRight } from "react-icons/bs";
import { add, format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

const WELL_BEING = {
  1: ASSETS.sunnyGIF,
  0.5: ASSETS.partlyCloudyGIF,
  0: ASSETS.cloudyGIF,
};

const getWellBeingStatusImage = (well_being_status) => {
  if (well_being_status <= 0.3) {
    return 0;
  } else if (well_being_status <= 0.7 && well_being_status > 0.3) {
    return 0.5;
  } else {
    return 1;
  }
};

function MeetingSession({ session, setVideoId, setIndex }) {
  console.log(session);
  const formattedStartTime = format(
    parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)),
    "HH:mm"
  );
  const formattedEndTime = format(
    add(parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)), {
      seconds: session.duration,
    }),
    "HH:mm"
  );
  return (
    <div
      className="session-item-wrapper"
      style={{
        cursor: session.status === "ANALYZED" ? "pointer" : "not-allowed",
      }}
      onClick={() => {
        if (session.status === "ANALYZED") {
          setVideoId(session["video_id"], "meeting");
          setIndex(1);
        }
      }}
    >
      <div className="session-item-date-time">
        <div className="session-item-date">
          {format(
            parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)),
            "LLL d, yyyy"
          )}
        </div>
        <div className="session-item-time">
          {formattedStartTime} - {formattedEndTime}
        </div>
      </div>
      <div className="session-item-under-weather-wrapper">
        <div className="session-item-under-weather-heading">
          Under the Weather Status
        </div>
        <div className="session-item-under-weather-icon">
          <img
            src={WELL_BEING[getWellBeingStatusImage(session.well_being_status)]}
            alt="wellbeing-status"
          />
        </div>
      </div>
      <div className="session-item-summary-wrapper">
        <div className="session-item-summary-heading">Session Summary</div>
        <div className="session-item-summary-body">{session.description}</div>
      </div>
      <div className="session-item-edit-icon-wrapper">
        <div className="session-item-edit-icon">
          <img src={ASSETS.pageEditIcon} alt="page-edit" />
        </div>
        <div className="session-item-left-arrow">
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
}

export default MeetingSession;
