import React from "react";

function RealtimeSessionDap({ dap }) {
  return (
    <div className="realtime-session-progress-notes-wrapper">
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Summary</div>
        <div className="realtime-session-progress-notes-body">
          {dap?.summary}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Data</div>
        <div className="realtime-session-progress-notes-body">{dap?.data}</div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">
          Assessment
        </div>
        <div className="realtime-session-progress-notes-body">
          {dap?.assessment}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Plan</div>
        <div className="realtime-session-progress-notes-body">{dap?.plan}</div>
      </div>
    </div>
  );
}

export default RealtimeSessionDap;
