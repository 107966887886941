import React, { useEffect, useState } from "react";
import Loader from "../../../../../../../components/loader/Loader";
import ASSETS from "../../../../../../../assets/Assets";
import { getSingleAssessmentAPI } from "../../../../../../../api/assessments";
import { toast } from "react-hot-toast";
import "./AssessmentDetail.css";

const convertToLocalTimezone = (timestamp) => {
  let t = timestamp.split(/[- :]/);

  // Apply each element to the Date function
  let d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

  return d;
};

const Header = ({ showAssessmentDetailCallback, assessment }) => {
  return (
    <div className="assessment-detail-header">
      <div
        className="assessment-detail-header-back-button"
        onClick={() => {
          showAssessmentDetailCallback("");
        }}
      >
        &lt;
      </div>
      <div className="assessment-detail-header-date-time">
        <div className="assessment-detail-header-date">
          {new Date(assessment["completed_at"]).toString().slice(4, 16)}
        </div>
        <div className="assessment-detail-header-time">
          {convertToLocalTimezone(
            assessment["completed_at"]
          ).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

const AnswerList = ({ questionList }) => {
  return (
    <div className="assessment-detail-answer-list">
      {questionList.map((item) => (
        <div
          key={item["assessment_question_id"]}
          className="assessment-detail-answer-list-item"
          style={
            item["assessment_option_score"] > 2
              ? { backgroundColor: "rgba(236, 116, 64, 0.2)" }
              : item["assessment_option_score"] > 1
              ? { backgroundColor: "rgba(241, 208, 124, 0.2)" }
              : {}
          }
        >
          <div className="assessment-detail-answer-list-item-number">
            <span
              style={
                item["assessment_option_score"] > 2
                  ? { backgroundColor: "rgba(236, 116, 64, 0.7)" }
                  : item["assessment_option_score"] > 1
                  ? { backgroundColor: "rgba(241, 208, 124, 0.7)" }
                  : { backgroundColor: "rgba(221, 226, 233, 0.7)" }
              }
            >
              {item["assessment_question_order"]}
            </span>
          </div>
          <div className="assessment-detail-answer-list-item-question">
            {item["assessment_question"]}
          </div>
          <div className="assessment-detail-answer-list-item-option">
            <div>{item["assessment_option"]}</div>
          </div>
          <div className="assessment-detail-answer-list-item-score">
            <div>
              {item["assessment_option_score"]}
              <span style={{ fontSize: "14px", fontWeight: 400 }}>/3</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const processData = (results) => {
  let questions = results["questions"];
  let answers = results["answers"];

  let data = [];

  for (const answer of answers) {
    for (const question of questions) {
      if (answer["selected_option_id"] === question["assessment_option_id"]) {
        data.push(question);
      }
    }
  }
  const sortedData = data.sort(function (a, b) {
    return a.assessment_question_order - b.assessment_question_order;
  });

  return sortedData;
};

const AssessmentDetail = ({ showAssessmentDetailCallback, assessment }) => {
  const assessmentId = assessment["assessment_id"];
  const assessmentType = assessment["assessment_type"];
  const [showLoader, setShowLoader] = useState(true);
  const [questionList, setQuestionList] = useState([]);

  // const results = {
  //   questions: [
  //     {
  //       assessment_question_order: 1,
  //       assessment_question: "Little interest or pleasure in doing things",
  //       assessment_option_id: "fdd410b1-1e31-416f-9618-2e4b08a2547d",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "026d9836-c4d4-4a68-b6d0-4188b33bf702",
  //     },
  //     {
  //       assessment_question_order: 1,
  //       assessment_question: "Little interest or pleasure in doing things",
  //       assessment_option_id: "1b51e052-7b3b-4aa9-ae9c-a5420b4bb02b",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "026d9836-c4d4-4a68-b6d0-4188b33bf702",
  //     },
  //     {
  //       assessment_question_order: 1,
  //       assessment_question: "Little interest or pleasure in doing things",
  //       assessment_option_id: "d05330cc-cdbc-41d5-9d8c-0b7e848d331c",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "026d9836-c4d4-4a68-b6d0-4188b33bf702",
  //     },
  //     {
  //       assessment_question_order: 1,
  //       assessment_question: "Little interest or pleasure in doing things",
  //       assessment_option_id: "7010f842-582f-47bd-bef0-86d6a4ece645",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "026d9836-c4d4-4a68-b6d0-4188b33bf702",
  //     },
  //     {
  //       assessment_question_order: 2,
  //       assessment_question: "Feeling down, depressed, or hopeless",
  //       assessment_option_id: "c0200d54-c0f3-4c5a-a042-3fb4b89e3f5d",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "6f756e38-2f78-47c3-a3fb-7410080a1590",
  //     },
  //     {
  //       assessment_question_order: 2,
  //       assessment_question: "Feeling down, depressed, or hopeless",
  //       assessment_option_id: "cbe7c053-ea4c-4623-b860-3de9e6f9ce61",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "6f756e38-2f78-47c3-a3fb-7410080a1590",
  //     },
  //     {
  //       assessment_question_order: 2,
  //       assessment_question: "Feeling down, depressed, or hopeless",
  //       assessment_option_id: "97947712-b4bb-4313-8cbd-f2d152830eb9",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "6f756e38-2f78-47c3-a3fb-7410080a1590",
  //     },
  //     {
  //       assessment_question_order: 2,
  //       assessment_question: "Feeling down, depressed, or hopeless",
  //       assessment_option_id: "6a551607-5daf-4759-8687-324143647071",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "6f756e38-2f78-47c3-a3fb-7410080a1590",
  //     },
  //     {
  //       assessment_question_order: 3,
  //       assessment_question:
  //         "Trouble falling or staying asleep, or sleeping too much",
  //       assessment_option_id: "df2e8bce-78ca-408b-b77d-1c2d04b4aff1",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "9ae41c91-7846-48a3-ac42-62fb5ef5c06c",
  //     },
  //     {
  //       assessment_question_order: 3,
  //       assessment_question:
  //         "Trouble falling or staying asleep, or sleeping too much",
  //       assessment_option_id: "93f764f1-ba97-4f90-a3c5-687313f9b3b1",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "9ae41c91-7846-48a3-ac42-62fb5ef5c06c",
  //     },
  //     {
  //       assessment_question_order: 3,
  //       assessment_question:
  //         "Trouble falling or staying asleep, or sleeping too much",
  //       assessment_option_id: "2c05a1a3-766f-4338-938a-8cfc77459ddd",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "9ae41c91-7846-48a3-ac42-62fb5ef5c06c",
  //     },
  //     {
  //       assessment_question_order: 3,
  //       assessment_question:
  //         "Trouble falling or staying asleep, or sleeping too much",
  //       assessment_option_id: "f3482199-1f25-43ac-946d-6e109b25bfc8",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "9ae41c91-7846-48a3-ac42-62fb5ef5c06c",
  //     },
  //     {
  //       assessment_question_order: 4,
  //       assessment_question: "Feeling tired or having little energy",
  //       assessment_option_id: "d907ed19-92b6-4299-ad8f-b353372707dd",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "9382388a-2e4c-4a53-9b78-b105c523df2d",
  //     },
  //     {
  //       assessment_question_order: 4,
  //       assessment_question: "Feeling tired or having little energy",
  //       assessment_option_id: "d2fca442-5363-4801-8205-22d0a81aed32",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "9382388a-2e4c-4a53-9b78-b105c523df2d",
  //     },
  //     {
  //       assessment_question_order: 4,
  //       assessment_question: "Feeling tired or having little energy",
  //       assessment_option_id: "2faa6c87-38b8-4b7a-883c-166ed65e23eb",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "9382388a-2e4c-4a53-9b78-b105c523df2d",
  //     },
  //     {
  //       assessment_question_order: 4,
  //       assessment_question: "Feeling tired or having little energy",
  //       assessment_option_id: "91c7e133-4195-4be8-a64c-b6667f396bbe",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "9382388a-2e4c-4a53-9b78-b105c523df2d",
  //     },
  //     {
  //       assessment_question_order: 5,
  //       assessment_question: "Poor appetite or overeating",
  //       assessment_option_id: "dbb59481-a060-42a7-927e-ebca52cac930",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "2ee46f50-5f22-4f57-994e-726ebb3a2ac3",
  //     },
  //     {
  //       assessment_question_order: 5,
  //       assessment_question: "Poor appetite or overeating",
  //       assessment_option_id: "c1e51b01-dd66-48bf-a6fa-90d657272da5",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "2ee46f50-5f22-4f57-994e-726ebb3a2ac3",
  //     },
  //     {
  //       assessment_question_order: 5,
  //       assessment_question: "Poor appetite or overeating",
  //       assessment_option_id: "ed349310-18d3-42cf-8164-da020683a41a",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "2ee46f50-5f22-4f57-994e-726ebb3a2ac3",
  //     },
  //     {
  //       assessment_question_order: 5,
  //       assessment_question: "Poor appetite or overeating",
  //       assessment_option_id: "5f6ee904-ffc0-47d7-b955-8994acb42c87",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "2ee46f50-5f22-4f57-994e-726ebb3a2ac3",
  //     },
  //     {
  //       assessment_question_order: 6,
  //       assessment_question:
  //         "Feeling bad about yourself or that you are a failure or have let yourself or your family down",
  //       assessment_option_id: "cae1dc08-036a-4ea9-92d9-36e85f67fe8b",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "37b75035-76f9-463c-97a1-6b70b931b4cc",
  //     },
  //     {
  //       assessment_question_order: 6,
  //       assessment_question:
  //         "Feeling bad about yourself or that you are a failure or have let yourself or your family down",
  //       assessment_option_id: "9eb5e80a-7341-40fc-bcee-e12fd573822b",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "37b75035-76f9-463c-97a1-6b70b931b4cc",
  //     },
  //     {
  //       assessment_question_order: 6,
  //       assessment_question:
  //         "Feeling bad about yourself or that you are a failure or have let yourself or your family down",
  //       assessment_option_id: "db238f3d-85f6-4ae9-848b-4eca470dcfce",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "37b75035-76f9-463c-97a1-6b70b931b4cc",
  //     },
  //     {
  //       assessment_question_order: 6,
  //       assessment_question:
  //         "Feeling bad about yourself or that you are a failure or have let yourself or your family down",
  //       assessment_option_id: "405ba4d5-d20f-40e5-a439-705e811cb080",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "37b75035-76f9-463c-97a1-6b70b931b4cc",
  //     },
  //     {
  //       assessment_question_order: 7,
  //       assessment_question:
  //         "I don’t feel that relationships at work are positive and feel that there is friction and anger between colleagues.",
  //       assessment_option_id: "04b5a16b-5ba2-4ff5-8fc5-b4622af29910",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "901a73aa-e9df-4329-bc38-d266d50ae94b",
  //     },
  //     {
  //       assessment_question_order: 7,
  //       assessment_question:
  //         "I don’t feel that relationships at work are positive and feel that there is friction and anger between colleagues.",
  //       assessment_option_id: "c9b9b75b-c68c-463d-aa5d-2628cbe1dc5c",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "901a73aa-e9df-4329-bc38-d266d50ae94b",
  //     },
  //     {
  //       assessment_question_order: 7,
  //       assessment_question:
  //         "I don’t feel that relationships at work are positive and feel that there is friction and anger between colleagues.",
  //       assessment_option_id: "cc93f518-8f07-4213-a116-7f0ab2bd75b3",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "901a73aa-e9df-4329-bc38-d266d50ae94b",
  //     },
  //     {
  //       assessment_question_order: 7,
  //       assessment_question:
  //         "I don’t feel that relationships at work are positive and feel that there is friction and anger between colleagues.",
  //       assessment_option_id: "f69fc5c0-bbb6-4fa1-99bb-e46460833a3f",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "901a73aa-e9df-4329-bc38-d266d50ae94b",
  //     },
  //     {
  //       assessment_question_order: 8,
  //       assessment_question:
  //         "Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual",
  //       assessment_option_id: "a29d863b-fac8-43d1-9cc8-2eb49718411d",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "f99b1e24-d8cf-4a85-8d8e-73f5b15ab262",
  //     },
  //     {
  //       assessment_question_order: 8,
  //       assessment_question:
  //         "Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual",
  //       assessment_option_id: "7e396cad-6ee1-4d1c-81f5-f344fc7bb1e7",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "f99b1e24-d8cf-4a85-8d8e-73f5b15ab262",
  //     },
  //     {
  //       assessment_question_order: 8,
  //       assessment_question:
  //         "Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual",
  //       assessment_option_id: "6b07f638-e525-4483-9380-9b4bb04210df",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "f99b1e24-d8cf-4a85-8d8e-73f5b15ab262",
  //     },
  //     {
  //       assessment_question_order: 8,
  //       assessment_question:
  //         "Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual",
  //       assessment_option_id: "612f299b-24f4-49a0-833b-bc764d7b26b1",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "f99b1e24-d8cf-4a85-8d8e-73f5b15ab262",
  //     },
  //     {
  //       assessment_question_order: 9,
  //       assessment_question:
  //         "Thoughts that you would be better off dead, or of hurting yourself",
  //       assessment_option_id: "b8aa9a24-6bdf-4e6b-87a1-bef4a6702ed3",
  //       assessment_type: "PHQ9",
  //       assessment_option: "More than half the days",
  //       assessment_option_score: 2,
  //       assessment_question_id: "563fe8d8-fe92-416a-b06b-45d81c9f00d1",
  //     },
  //     {
  //       assessment_question_order: 9,
  //       assessment_question:
  //         "Thoughts that you would be better off dead, or of hurting yourself",
  //       assessment_option_id: "e7348e71-ceb2-443d-8c7d-622cf4beec5b",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Not at all",
  //       assessment_option_score: 0,
  //       assessment_question_id: "563fe8d8-fe92-416a-b06b-45d81c9f00d1",
  //     },
  //     {
  //       assessment_question_order: 9,
  //       assessment_question:
  //         "Thoughts that you would be better off dead, or of hurting yourself",
  //       assessment_option_id: "218b6e00-82b7-4086-9c3a-a02c42fa5086",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Several Days",
  //       assessment_option_score: 1,
  //       assessment_question_id: "563fe8d8-fe92-416a-b06b-45d81c9f00d1",
  //     },
  //     {
  //       assessment_question_order: 9,
  //       assessment_question:
  //         "Thoughts that you would be better off dead, or of hurting yourself",
  //       assessment_option_id: "1ad0a5b2-5947-4589-b071-0f4d3e8c1bea",
  //       assessment_type: "PHQ9",
  //       assessment_option: "Nearly every day",
  //       assessment_option_score: 3,
  //       assessment_question_id: "563fe8d8-fe92-416a-b06b-45d81c9f00d1",
  //     },
  //   ],
  //   answers: [
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "026d9836-c4d4-4a68-b6d0-4188b33bf702",
  //       selected_option_id: "fdd410b1-1e31-416f-9618-2e4b08a2547d",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "9382388a-2e4c-4a53-9b78-b105c523df2d",
  //       selected_option_id: "2faa6c87-38b8-4b7a-883c-166ed65e23eb",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "6f756e38-2f78-47c3-a3fb-7410080a1590",
  //       selected_option_id: "97947712-b4bb-4313-8cbd-f2d152830eb9",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "563fe8d8-fe92-416a-b06b-45d81c9f00d1",
  //       selected_option_id: "218b6e00-82b7-4086-9c3a-a02c42fa5086",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "9ae41c91-7846-48a3-ac42-62fb5ef5c06c",
  //       selected_option_id: "2c05a1a3-766f-4338-938a-8cfc77459ddd",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "901a73aa-e9df-4329-bc38-d266d50ae94b",
  //       selected_option_id: "c9b9b75b-c68c-463d-aa5d-2628cbe1dc5c",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "2ee46f50-5f22-4f57-994e-726ebb3a2ac3",
  //       selected_option_id: "c1e51b01-dd66-48bf-a6fa-90d657272da5",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "37b75035-76f9-463c-97a1-6b70b931b4cc",
  //       selected_option_id: "405ba4d5-d20f-40e5-a439-705e811cb080",
  //     },
  //     {
  //       assessment_id: "test_therapy_id",
  //       question_id: "f99b1e24-d8cf-4a85-8d8e-73f5b15ab262",
  //       selected_option_id: "612f299b-24f4-49a0-833b-bc764d7b26b1",
  //     },
  //   ],
  // };

  useEffect(() => {
    setShowLoader(true);
    // get question list via calling the api
    getSingleAssessmentAPI(assessmentType, assessmentId)
      .then((res) => {
        // process the question
        setQuestionList(processData(res.data));
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error("Failed to fetch assessment");
        console.log(err);
      });
  }, []);

  return (
    <div className="assessment-detail-container">
      <Header
        showAssessmentDetailCallback={showAssessmentDetailCallback}
        assessment={assessment}
      />
      {showLoader ? <Loader /> : <AnswerList questionList={questionList} />}
    </div>
  );
};

export default AssessmentDetail;
