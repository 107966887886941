export const formatDataset = (data) => {
  let dataset = data.map((row) => {
    return {
      x: new Date(row["completed_at"]).toString().slice(4, 16),
      y: row["score"],
    };
  });

  

  return [
    {
      label: "score",
      data: dataset,
      fill: false,
      borderColor: "rgb(95, 108, 225)",
      tension: 0.3,
    },
  ];
};
