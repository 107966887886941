import React from "react";

function RealtimeSessionBirp({ birp }) {
  return (
    <div className="realtime-session-progress-notes-wrapper">
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Summary</div>
        <div className="realtime-session-progress-notes-body">
          {birp?.summary}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Behaviour</div>
        <div className="realtime-session-progress-notes-body">
          {birp?.behavior}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Intensity</div>
        <div className="realtime-session-progress-notes-body">
          {birp?.intensity}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Response</div>
        <div className="realtime-session-progress-notes-body">
          {birp?.response}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Plan</div>
        <div className="realtime-session-progress-notes-body">{birp?.plan}</div>
      </div>
    </div>
  );
}

export default RealtimeSessionBirp;
