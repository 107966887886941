import React, { useState } from "react";
import {
  loginPageSvg,
  logoWithName,
  therapistUnverifiedSvg,
} from "../../assets/Assets";
import { Button as AwsButton, useAuthenticator } from "@aws-amplify/ui-react";

import "./Unverified.css";
import UploadProfilePic from "./components/UploadProfilePic";
import UploadCertification from "./components/UploadCertification";
import { Heading } from "@aws-amplify/ui-react";
import { getUserFilesUploadURL, uploadFiles } from "../../api/s3";
import { useDispatch, useSelector } from "react-redux";
import { updateTherapistAPI } from "../../api/therapist";
import { setTherapist } from "../../store/therapist/therapistSlice";
import { Auth } from "aws-amplify";

function Unverified({ logOut }) {
  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [loading, setLoading] = useState(false);
  const [
    profile_photo_bucket,
    profile_photo_key,
    verification_doc_bucket,
    verification_doc_key,
  ] = useSelector((store) => [
    store.therapist.profile_photo_bucket,
    store.therapist.profile_photo_key,
    store.therapist.verification_doc_bucket,
    store.therapist.verification_doc_key,
  ]);
  const dispatch = useDispatch();
  //console.log(profile_photo_bucket, profile_photo_key);

  const uploaded =
    !!profile_photo_bucket &&
    !!profile_photo_key &&
    !!verification_doc_bucket &&
    !!verification_doc_key;
  const [profilePic, setProfilePic] = useState(null);
  const [verificationDoc, setVerificationDoc] = useState(null);

  const handleUpload = async () => {
    setLoading(true);
    const [imageUpload, verificationDocUpload] = await getUserFilesUploadURL(
      therapist_id
    );
    // console.log(imageUpload.url, verificationDocUpload.url);

    await Promise.all([
      uploadFiles(imageUpload.url, profilePic),
      uploadFiles(verificationDocUpload.url, verificationDoc),
    ]);

    const updateBody = {
      profile_photo_bucket: imageUpload.bucket,
      profile_photo_key: imageUpload.key,
      verification_doc_bucket: verificationDocUpload.bucket,
      verification_doc_key: verificationDocUpload.key,
    };

    const data = await updateTherapistAPI(therapist_id, updateBody);
    dispatch(setTherapist(data[0]));
    setLoading(false);
  };

  const handleOnSkipClick = async () => {
    try {
      user.updateAttributes(
        [
          {
            Name: "custom:user_status",
            Value: "SKIPPED",
          },
        ],
        async (err, result, details) => {
          const newUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          window.location.reload();
        }
      );
      // console.log(user);
      // console.log(newUser);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="auth-page-wrapper">
      <div className="auth-page_column auth-page_left">
        <div className="login-form-wrapper">
          <img className="login-form-logo" src={logoWithName} alt="logo" />
          {uploaded && (
            <div
              style={{
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <img src={therapistUnverifiedSvg} alt="" />

              <div>
                Thank you for registering with us. Our team will review your
                certifications and supporting documents, and we will be in touch
                shortly.
              </div>
              <AwsButton
                variation="warning"
                onClick={() => {
                  logOut();
                }}
              >
                Logout
              </AwsButton>
              <AwsButton
                variation="primary"
                onClick={() => {
                  logOut();
                }}
              >
                Logout
              </AwsButton>
              <AwsButton
                variation="primary"
                isLoading={loading}
                onClick={handleOnSkipClick}
              >
                Skip
              </AwsButton>
            </div>
          )}
          {!uploaded && (
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Heading
                // padding={`${tokens.space.xs} 0 0 ${tokens.space.xl}`}
                level={3}
                textAlign="left"
              >
                Some final steps
              </Heading>
              <div className="unverified-upload-wrapper">
                <span>Upload Your Profile Pic</span>
                <UploadProfilePic
                  profilePic={profilePic}
                  setProfilePic={setProfilePic}
                />
              </div>
              <div className="unverified-upload-wrapper">
                <span>Upload Your Certificate</span>
                <UploadCertification
                  file={verificationDoc}
                  setFile={setVerificationDoc}
                />
              </div>
              <AwsButton
                variation="primary"
                isDisabled={!verificationDoc || !profilePic}
                isLoading={loading}
                onClick={handleUpload}
              >
                Upload
              </AwsButton>
              <AwsButton
                variation="primary"
                isLoading={loading}
                onClick={handleOnSkipClick}
              >
                Skip
              </AwsButton>
            </div>
          )}
        </div>
      </div>
      <div className="auth-page_column auth-page_right">
        <div className="auth-page-img-wrapper">
          <img className="auth-page-img" src={loginPageSvg} alt="login" />
        </div>
      </div>
    </div>
  );
}

export default Unverified;
