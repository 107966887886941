import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

// Assessments Frequency
export const createAssessmentFrequencyAPI = async (
  therapyId,
  assessmentType,
  frequency,
  startDate,
  endDate
) => {
  const baseURL = config.API_URL;
  const path = "/assessments_frequency";
  const body = {
    therapy_id: therapyId,
    assessment_type: assessmentType,
    frequency: frequency,
    start_date: startDate,
    end_date: endDate,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getAllAssessmentFrequenciesAPI = async (therapyId,patientId) => {
  const baseURL = config.API_URL;
  const path = "/assessments_frequency";
  const params = "?therapy_id=" + therapyId + "&patient_id=" + patientId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateAssessmentFrequencyAPI = async (
  therapyId,
  assessmentType,
  frequency
) => {
  const baseURL = config.API_URL;
  const path = "/assessments_frequency";
  const body = {
    therapy_id: therapyId,
    assessment_type: assessmentType,
    frequency: frequency,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const deleteAssessmentFrequencyAPI = async (
  therapyId,
  assessmentType
) => {
  const baseURL = config.API_URL;
  const path = "/assessments_frequency";
  const params =
    "?therapy_id=" + therapyId + "&assessment_type=" + assessmentType;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.delete(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

// Assessments and their details
export const getAllAssessmentAPI = async (therapyId) => {
  const baseURL = config.API_URL;
  const path = "/all_assessments";
  const params = "?therapy_id=" + therapyId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getSingleAssessmentAPI = async (assessmentType, assessmentId) => {
  const baseURL = config.API_URL;
  const path = "/assessments";
  const params =
    "?user_type=therapist" +
    "&assessment_type=" +
    assessmentType +
    "&assessment_id=" +
    assessmentId;

  return await axios.get(`${baseURL + path + params}`);
};
