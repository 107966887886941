import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const fetchAllMeetingAIAnalysisAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = `/v2/meetings/therapy_analysis?therapy_id=${therapy_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
  // const res = await axios.get(baseURL + path);
  return res.data;
};

export const fetchLatestMeetingAIAnalysisAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/latest_analysis?therapy_id=${therapy_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
  // const res = await axios.get(baseURL + path);
  return res.data;
};

export const fetchMeetingSummaryAPI = async (video_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/summary?video_id=${video_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
  // const res = await axios.get(baseURL + path);
  return res.data;
};

export const fetchMeetingPatientEmotionsAPI = async (video_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/patient_emotions?video_id=${video_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
  // const res = await axios.get(baseURL + path);
  return res.data;
};

// export const updateMeetingSummaryAPI = async (video_id, summary) => {
//   const baseURL = config.API_URL;
//   const path = `/meetings/summary?video_id=${video_id}`;
//   const body = {
//     summary,
//   };
//   const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
//   const res = await axios.put(baseURL + path, body, {
//     headers: {
//       Authorization: idToken,
//     },
//   });
//   return res;
// };

export const updateMeetingSummaryAPI = async (
  video_id,
  summary,
  subjective,
  objective,
  assessment,
  plan
) => {
  const baseURL = config.API_URL;
  const path = `/meetings/summary?video_id=${video_id}`;
  const body = {
    summary,
    subjective,
    objective,
    assessment,
    plan,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(baseURL + path, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return res;
};

export const getSignificantEmotionsDataAPI = async (video_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/significant_emotions?video_id=${video_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};
