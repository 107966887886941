import MeetingSessionDetails from "./MeetingSessionDetails";
import RealtimeSessionDetails from "./RealtimeSessionDetails";
import "./Session.css";

function Session({ setIndex, videoId, currentSession }) {
  console.log(currentSession);
  return (
    <>
      {currentSession.type === "meeting" && (
        <MeetingSessionDetails
          currentSession={currentSession}
          setIndex={setIndex}
          videoId={videoId}
        />
      )}
      {currentSession.type === "realtime" && (
        <RealtimeSessionDetails
          currentSession={currentSession}
          setIndex={setIndex}
          videoId={videoId}
        />
      )}
    </>
  );
}

export default Session;
