export const mysqlDatetimeToJavascriptDatetime = (datetime) => {
  // Split timestamp into [ Y, M, D, h, m, s ]
  const t = datetime.split(/[- :]/);

  // Apply each element to the Date function
  return new Date(
    Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
  ).toISOString();
};

export const formatDateToYYYYMMDDHHMMSS = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
