import React, { useState } from 'react'
import './SettingList.css'
import ASSETS from '../../../assets/Assets'
import { FaUserCog, FaSignOutAlt } from 'react-icons/fa'
import { AiOutlineLock } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import LockResetIcon from '@mui/icons-material/LockReset'

export default function SettingList({ logOut }) {
  let path = window.location.pathname

  const [searchParams, setSearchParams] = useSearchParams()
  const currentSetting = searchParams.get('tab')

  const handleSelectedTab = (tab) => {
    searchParams.set('tab', tab)
    setSearchParams(searchParams)
  }

  return (
    <div className='setting-list-wrapper'>
      <span className='heading'>Settings</span>
      <div className='settings-accounts-div'>
        <div
          className={
            currentSetting === 'profile'
              ? 'settings-list-option-selected'
              : 'settings-list-option'
          }
          onClick={() => {
            // updateSelectedOption("profile");
            handleSelectedTab('profile')
          }}
        >
          <div style={{ background: '#EC7440' }}>
            <img src={ASSETS.profileIcon} alt='profile-icon' />
          </div>
          <span
            className={
              currentSetting === 'profile' ? 'sub-heading-white' : 'sub-heading'
            }
          >
            Profile
          </span>
        </div>
        <div
          className={
            currentSetting === 'password'
              ? 'settings-list-option-selected'
              : 'settings-list-option'
          }
          onClick={() => {
            // updateSelectedOption("profile");
            handleSelectedTab('password')
          }}
        >
          <div style={{ background: '#EC7440' }}>
            <LockResetIcon />
          </div>
          <span
            className={
              currentSetting === 'password'
                ? 'sub-heading-white'
                : 'sub-heading'
            }
          >
            Change password
          </span>
        </div>
        <div
          className={
            currentSetting === 'teleHealth'
              ? 'settings-list-option-selected'
              : 'settings-list-option'
          }
          onClick={() => {
            // updateSelectedOption("profile");
            handleSelectedTab('teleHealth')
          }}
        >
          <div style={{ background: '#EC7440' }}>
            <img
              src={ASSETS.telemedicinePng}
              alt='profile-icon'
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
                width: '25px',
                height: '25px',
              }}
            />
          </div>
          <span
            className={
              currentSetting === 'teleHealth'
                ? 'sub-heading-white'
                : 'sub-heading'
            }
          >
            Telehealth
          </span>
        </div>
        <div className='settings-list-option' onClick={logOut}>
          <div>
            <FaSignOutAlt className='settings-option-icon' color={'#888'} />
          </div>
          <span className='sub-heading'>Log out</span>
        </div>
      </div>
    </div>
  )
}
