import React from "react";

function RealtimeSessionSoap({ soap }) {
  return (
    <div className="realtime-session-progress-notes-wrapper">
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Summary</div>
        <div className="realtime-session-progress-notes-body">
          {soap?.summary}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">
          Subjective
        </div>
        <div className="realtime-session-progress-notes-body">
          {soap?.subjective}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Objective</div>
        <div className="realtime-session-progress-notes-body">
          {soap?.objective}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">
          Assessment
        </div>
        <div className="realtime-session-progress-notes-body">
          {soap?.assessment}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Plan</div>
        <div className="realtime-session-progress-notes-body">{soap?.plan}</div>
      </div>
    </div>
  );
}

export default RealtimeSessionSoap;
