import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import ASSETS from "../../../../assets/Assets";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const secondsToHHMMSS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours =
    hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
  const formattedMinutes = `${minutes.toString().padStart(2, "0")}`;
  const formattedSeconds = `${remainingSeconds.toString().padStart(2, "0")}`;

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  layout: {
    padding: {
      left: 0,
    },
  },
  scales: {
    x: {
      stepSize: 30,
      callback: function (value, index, ticks) {
        const t = secondsToHHMMSS(value);
        console.log("hi -------- t-       ", t);
        return t;
      },
    },
    y: {
      max: 1,
      min: -1,
      grid: { display: false },
      ticks: { display: false, padding: 15, stepSize: 1 },
    },
  },
};

const labels = [0];

const transcriptImages = {
  1: ASSETS.negativeTranscript,
  "-1": ASSETS.positiveTranscript,
};

export const data = {
  labels,
  datasets: [
    {
      fill: {
        value: 0,
        above: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const chartWidth = chartArea.right - chartArea.left;
          const chartHeight = chartArea.bottom - chartArea.top;
          let width = chartWidth;
          let height = chartHeight;
          let gradient = ctx.createLinearGradient(
            0,
            (chartArea.bottom + chartArea.top) / 2,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, "rgba(142, 152, 250, 1)");
          gradient.addColorStop(1, "rgba(142, 152, 250, 0)");
          return gradient;
        },
        below: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const chartWidth = chartArea.right - chartArea.left;
          const chartHeight = chartArea.bottom - chartArea.top;
          let width = chartWidth;
          let height = chartHeight;
          let gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            (chartArea.top + chartArea.bottom) / 2
          );
          gradient.addColorStop(0, "rgba(249, 146, 101, 0)");
          gradient.addColorStop(1, "rgba(249, 146, 101, 1)");
          return gradient;
        },
      },
      label: "Sentiment Analysis",
      data: [0],
      //   borderColor: 'rgb(53, 162, 235)',
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      tension: 0.5,
      pointStyle: false,
    },
  ],
};

function RealtimeSessionSentiments({ clientSentimentScore }) {
  const chartRef = useRef(null);
  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      console.log("CanvasRenderingContext2D", chart.ctx);
      console.log("HTMLCanvasElement", chart.canvas);
    }
  }, []);

  useEffect(() => {
    const chart = chartRef.current;
    if (clientSentimentScore && clientSentimentScore.length > 0) {
      chart.data.labels = clientSentimentScore.map((sentimment) =>
        secondsToHHMMSS(sentimment.time)
      );
      chart.data.datasets[0].data = clientSentimentScore.map(
        (sentimment) => sentimment.score
      );
      console.log(chart.data.datasets[0]);
      chart.update();
    }
  }, [clientSentimentScore]);
  return (
    <div
      style={{
        flex: "1 1 auto",
        borderRadius: "1.5rem",
        border: "1px solid rgba(40,40,96,0.15)",
        padding: "1rem 1rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={ASSETS.positiveTranscript} width={30} />
          <span>Positive Transcript</span>
        </div>
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={ASSETS.negativeTranscript} width={30} />
          <span>Positive Transcript</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: "1 1 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            paddingBottom: "2.5rem",
            paddingTop: "0.5rem",
            width: "2rem",
          }}
        >
          <img src={ASSETS.positiveTranscript} width={30} />
          <img src={ASSETS.negativeTranscript} width={30} />
        </div>
        <div style={{ width: "calc(100% - 2rem)" }}>
          <Line ref={chartRef} options={options} data={data} />
        </div>
      </div>
    </div>
  );
}

export default RealtimeSessionSentiments;
