import React, { useEffect, useState } from "react";
import AssessmentSummary from "./components/AssessmentSummary/AssessmentSummary";
import AddAssessment from "./components/AddAssessment/AddAssessment";
import AssessmentsScore from "./components/AssessmentsScore/AssessmentsScore";
import AssessmentDetail from "./components/AssessmentDetail/AssessmentDetail";
import Loader from "../../../../../components/loader/Loader";
import "./Assessments.css";
import { toast } from "react-hot-toast";
import {
  deleteAssessmentFrequencyAPI,
  getAllAssessmentFrequenciesAPI,
  updateAssessmentFrequencyAPI,
  getAllAssessmentAPI,
} from "../../../../../api/assessments";

const processData = (data) => {
  let completed = 0;
  let phq9 = 0;
  let gad7 = 0;
  let aiCheckIn = 0;
  let missed = 0;

  for (let i in data) {
    if (data[i]["score"] === -1) {
      if (new Date(data[i]["due_date"]) < new Date()) missed++;
    } else {
      completed++;
      if (data[i]["assessment_type"] === "GAD7") {
        gad7++;
      }
      if (data[i]["assessment_type"] === "PHQ9") {
        phq9++;
      }
      if (data[i]["assessment_type"] === "AI CHECK IN") {
        aiCheckIn++;
      }
    }
  }

  return {
    completed: completed,
    phq9: phq9,
    gad7: gad7,
    aiCheckIn: aiCheckIn,
    missed: missed,
  };
};

const Assessments = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const therapyId = queryParameters.get("therapy_id");
  const patientId = queryParameters.get("patient_id");

  const [showAssessmentDetail, setShowAssessmentDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [assessment, setAssessment] = useState("");
  const [callAPI, setCallAPI] = useState(false);

  const [summaryData, setSummaryData] = useState({
    completed: 0,
    phq9: 0,
    gad7: 0,
    missed: 0,
    aiCheckIn: 0,
  });

  const [frequencyData, setFrequencyData] = useState({
    therapy_id: "",
    isPHQ9Present: false,
    isGAD7Present: false,
    isAICheckInPresent: false,
    isVerified: false,
    frequency: [
      // {
      //   assessmentType: "PHQ9",
      //   frequency: "WEEKLY",
      //   lastTaken: "",
      //   dueDate: "",
      // },
    ],
  });

  const [assessmentData, setAssessmentData] = useState([]);

  const handleAddFrequencyCallback = () => {
    setCallAPI((prevState) => !prevState);
  };

  useEffect(() => {
    getAllAssessmentFrequenciesAPI(therapyId, patientId)
      .then((res) => {
        //console.log(res);
        setFrequencyData(res.data);
      })
      .catch((e) => {
        toast.error("Failed to fetch assessment");
        console.log("Failed to fetch freqeuecny of assessments");
      });
  }, [callAPI]);

  useEffect(() => {
    setShowLoader(true);
    getAllAssessmentAPI(therapyId)
      .then((res) => {
        //process for summary data
        setSummaryData(processData(res.data));

        setAssessmentData(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        toast.error("Failed to fetch all assessments");
        console.log(err);
      });
  }, []);

  const showAssessmentDetailCallback = (assessment) => {
    setAssessment(assessment);
    setShowAssessmentDetail((current) => !current);
  };

  const handleUpdateFrequencyChangeCallback = (event, assessmentType) => {
    let updatedFrequency = event.target.value;

    // change the state
    const updatedFrequencyData = { ...frequencyData };
    const frequencies = updatedFrequencyData["frequency"];

    for (let i in frequencies) {
      if (frequencies[i]["assessmentType"] === assessmentType) {
        frequencies[i]["frequency"] = updatedFrequency;
      }
    }
    setFrequencyData(updatedFrequencyData);

    // Call the api
    updateAssessmentFrequencyAPI(therapyId, assessmentType, updatedFrequency)
      .then((res) => {
        toast.success("Frquency Updated");
      })
      .catch((e) => {
        setCallAPI((prevState) => !prevState);
        toast.error("Failed to update");
        console.log(e);
      });
  };

  const handleFrequencyDeleteCallback = (assessmentType) => {
    // change the state
    //console.log(assessmentType);
    const updatedFrequencyData = { ...frequencyData };
    const frequencies = updatedFrequencyData["frequency"];

    for (let i in frequencies) {
      if (frequencies[i]["assessmentType"] === assessmentType) {
        delete frequencies[i];
      }
      if (assessmentType === "PHQ9") {
        updatedFrequencyData["isPHQ9Present"] = false;
      }
      if (assessmentType === "GAD7") {
        updatedFrequencyData["isGAD7Present"] = false;
      }
      if (assessmentType === "AI CHECK IN") {
        updatedFrequencyData["isAICheckInPresent"] = false;
      }
    }
    setFrequencyData(updatedFrequencyData);

    // Call the api
    deleteAssessmentFrequencyAPI(therapyId, assessmentType)
      .then((res) => {
        toast.success("Assessment Deleted");
      })
      .catch((e) => {
        setCallAPI((prevState) => !prevState);
        toast.error("Failed to delete");
        console.log(e);
      });
  };

  return (
    <div className="assessment-container">
      {!showAssessmentDetail ? (
        showLoader ? (
          <Loader />
        ) : (
          <div className="assessment-data-container">
            <AssessmentSummary summaryData={summaryData} />
            <div className="assessment-data-scroll-container">
              <AddAssessment
                frequencyData={frequencyData}
                handleUpdateFrequencyChangeCallback={
                  handleUpdateFrequencyChangeCallback
                }
                handleAddFrequencyCallback={handleAddFrequencyCallback}
                handleFrequencyDeleteCallback={handleFrequencyDeleteCallback}
              />
              <AssessmentsScore
                showAssessmentDetailCallback={showAssessmentDetailCallback}
                assessmentData={assessmentData}
              />
            </div>
          </div>
        )
      ) : (
        <AssessmentDetail
          showAssessmentDetailCallback={showAssessmentDetailCallback}
          assessment={assessment}
        />
      )}
    </div>
  );
};

export default Assessments;
