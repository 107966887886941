import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  liveSessionId: null,
  triggers: {},
  transcript: "",
  speakerLabel: {},
  clientSentimentScore: [
    {
      time: 0,
      score: 0,
    },
  ],
  note_id: null,
  transcriptArr: [],
  microphoneStatus: false,
  transcriptLang: "en",
};

const liveSessionDashboardSlice = createSlice({
  name: "live_session",
  initialState: initialState,
  reducers: {
    setTranscript: (state, action) => {
      state.transcript = state.transcript + action.payload;
      return state;
    },
    setLiveSessionId: (state, action) => {
      state.liveSessionId = action.payload;
      return state;
    },
    setSpeakerLabel: (state, action) => {
      state.speakerLabel = action.payload;
      return state;
    },
    clearTranscript: (state) => {
      state.transcript = "";
      return state;
    },
    removeClientSentimentScore: (state) => {
      state.clientSentimentScore = [{ time: 0, score: 0 }];
      return state;
    },
    addClientSentimentScore: (state, action) => {
      const isSame = false;
      state.clientSentimentScore.forEach((clientSentiment, i) => {
        if (clientSentiment.time === action.payload.time) {
          isSame = true;
          state.clientSentimentScore[i].score =
            (state.clientSentimentScore[i].score + action.payload.score) / 2;
        }
      });
      if (!isSame) {
        state.clientSentimentScore.push(action.payload);
        state.clientSentimentScore.sort((a, b) => a.time - b.time);
      }
      return state;
    },
    setNoteId: (state, action) => {
      state.note_id = action.payload;
      return state;
    },
    clearRealtimeSessionstore: (state) => {
      state = initialState;
      return state;
    },
    setTranscriptArr: (state, action) => {
      const changeArr = action.payload;
      const currArr = [...state.transcriptArr];
      changeArr.forEach((item) => {
        if (item.type === "speaker-change") {
          currArr.push({
            speaker: item.speaker,
            start: item.start,
            end: item.end,
            content: item.content,
            chat_time: item.chat_time.toString(),
          });
        } else {
          currArr[currArr.length - 1].content += " " + item.content;
          currArr[currArr.length - 1].end = item.end;
          currArr[currArr.length - 1].chat_time = item.chat_time.toString();
        }
      });
      state.transcriptArr = currArr;
      return state;
    },
    setTranscriptLanguage: (state, action) => {
      state.transcriptLang = action.payload;
      return state;
    },
    setMicrophoneStatus: (state, action) => {
      state.microphoneStatus = action.payload;
      return state;
    },
  },
});

export const {
  setTranscript,
  clearTranscript,
  setLiveSessionId,
  setSpeakerLabel,
  removeClientSentimentScore,
  addClientSentimentScore,
  setNoteId,
  clearRealtimeSessionstore,
  setTranscriptArr,
  setMicrophoneStatus,
  setTranscriptLanguage,
} = liveSessionDashboardSlice.actions;

export default liveSessionDashboardSlice.reducer;
