import { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button as AwsButton } from "@aws-amplify/ui-react";
import axios from "axios";
import { format, parse, parseISO } from "date-fns";
import Select, { StylesConfig } from "react-select";
import {
  createNewNotes,
  fetchNotesByTherapyId,
  read_report,
  updateNotes,
  update_report,
} from "../../../../../api/notes";
import { useSearchParams } from "react-router-dom";
import {
  getNotesFileUploadUrl,
  readUserFilesURL,
  uploadFiles,
} from "../../../../../api/s3";
import "./notesTabPanel.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../../utils/helpers";
import RealtimeSessionReport from "../RealtimePanel/RealtimeSessionReport/RealtimeSessionReport";
import { toast } from "react-hot-toast";
import {
  setLiveSessionId,
  setNoteId,
} from "../../../../../store/liveSession/liveSessionSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  createRealtimeSession,
  updateRealtimeSessionWithNoteId,
  getTranscriptUrl,
  getRealtimeSessionInfoById,
  getRealtimeSessionDetailsAPI
} from "../../../../../api/realtime";
import ASSETS from "../../../../../assets/Assets";
import { current } from "@reduxjs/toolkit";
import config from "../../../../../utils/config";
import buttonLoader from "./buttonLoader.gif";

const textStyle = {
  color: "#282860",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "150%",
};

function convertToAmPm(date) {
  // Create a new Date object with the provided time string
  var time = new Date(date);

  // Get hours and minutes
  var hours = time.getHours();
  var minutes = time.getMinutes();

  // Determine whether it's AM or PM
  var ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12' in AM/PM

  // Format the time as a string
  var formattedTime =
    hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

  return formattedTime;
}

const truncateNoteName = (noteName, maxLength) => {
  if (noteName.length <= maxLength) {
    return noteName; // No need to truncate
  } else {
    // Find the last space within the first 25 characters
    const lastSpaceIndex = noteName.lastIndexOf(" ", maxLength);

    if (lastSpaceIndex !== -1) {
      // Truncate at the last space
      return noteName.substring(0, lastSpaceIndex) + "..";
    } else {
      // If no space found, simply truncate at the specified length
      return noteName.substring(0, maxLength) + "..";
    }
  }
};

const NotesTabPanel = function ({ realtimeButton }) {
  const dispatch = useDispatch();

  const [noteScreen, setNoteScreen] = useState("all_notes");
  let [notesFetching, setNotesFetching] = useState(true);
  const [editorLoading, setEditorLoading] = useState(true);
  let [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState(null);
  const currentNoteCreating = useRef(false);
  const [currentNoteTag, setCurrentNoteTag] = useState("all");
  const [isNewNote, setIsNewNote] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [newNoteDialogOpen, setNewNoteDialogOpen] = useState(false);
  const [newNoteFileSaveLoading, setNewNoteFileSaveLoading] = useState(false);
  const editorRef = useRef(null);
  const therapy_id = searchParams.get("therapy_id");
  const [therapyNotesData, setTherapyNotesData] = useState("");
  const [isProgressNoteLoaded, setIsProgressNoteLoaded] = useState(false);

  const [realtimeReportModal, setRealtimeReportModal] = useState(false);
  //console.log(realtimeReportModal);

  const [soapData, setSoapData] = useState({});
  const [soapSuggestions, setSoapSuggestions] = useState({});

  const [dapData, setDapData] = useState({});
  const [dapSuggestions, setDapSuggestions] = useState({});

  const [birpData, setBirpData] = useState({});
  const [birpSuggestions, setBirpSuggestions] = useState({});

  const [girpData, setGirpData] = useState({});
  const [girpSuggestions, setGirpSuggestions] = useState({});

  const [addedSuggestions, setAddedSuggestions] = useState({});
  const [selectedLiveSessionId, setSelectedLiveSessionId] = useState(null);
  const [transcriptState, setTranscriptState] = useState([]);
  const [htmlNoteRecent, setHtmlNoteRecent] = useState("");
  const [transcriptsExpand, setTranscriptsExpand] = useState(false);

  const transcript = useSelector((store) => store.liveSession.transcript);
  const transcriptArr = useSelector((store) => store.liveSession.transcriptArr);
  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  const liveSessionId = useSelector((store) => store.liveSession.liveSessionId);

  // let transcript_slice = useSelector((store) => store.liveSession.transcriptArr);
  // transcript_slice = transcriptProp.length !== 0 ? JSON.parse(transcriptProp) : transcript_slice;

  function getParentDateOnNotes(allNotes) {
    let today = new Date().toString().slice(4, 16);
    let noteDate = new Date(allNotes[0].updated_at).toString().slice(4, 16);

    if (today === noteDate) {
      allNotes[0]["all_tags_parent_date"] = `Today, ${today}`;
    } else {
      allNotes[0]["all_tags_parent_date"] = noteDate;
    }

    //Assign parent date to first therapy_note & first progress_note
    let isFirstProgressNoteFound = false;
    let isFirstTherapyNoteFound = false;
    for (let i = 0; i < allNotes.length; i++) {
      if (isFirstTherapyNoteFound && isFirstProgressNoteFound) {
        break;
      }
      if (
        allNotes[i]["note_type"] === "ai_progress_notes" &&
        !isFirstProgressNoteFound
      ) {
        if (today === noteDate) {
          allNotes[i]["parent_date"] = `Today, ${today}`;
        } else {
          allNotes[i]["parent_date"] = noteDate;
        }
        isFirstProgressNoteFound = true;
      }

      if (
        allNotes[i]["note_type"] === "therapy_notes" &&
        !isFirstTherapyNoteFound
      ) {
        if (today === noteDate) {
          allNotes[i]["parent_date"] = `Today, ${today}`;
        } else {
          allNotes[i]["parent_date"] = noteDate;
        }
        isFirstTherapyNoteFound = true;
      }
    }

    for (let i = 1; i < allNotes.length; i++) {
      let previousNoteDate = new Date(allNotes[i - 1].updated_at)
        .toString()
        .slice(4, 16);
      let noteDate = new Date(allNotes[i].updated_at).toString().slice(4, 16);
      if (noteDate !== previousNoteDate) {
        allNotes[i]["parent_date"] = noteDate;
        allNotes[i]["all_tags_parent_date"] = noteDate;
      }
    }
  }

  useEffect(() => {
    if (noteScreen === "all_notes") fetchAllNotes(therapy_id);
  }, [noteScreen]);

  const fetchAllNotes = async (therapy_id) => {
    setNotesFetching(true);
    fetchNotesByTherapyId(therapy_id).then((data) => {
      //console.log(data);
      let allNotes = data.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      // Check for same date
      if (allNotes.length > 0) getParentDateOnNotes(allNotes);
      //console.log(allNotes);
      setNotes(allNotes);
    });
    setNotesFetching(false);
  };

  const fetchNoteFiles = async (note) => {
    setEditorLoading(true);
    const noteURL = await readUserFilesURL(note.note_bucket_key);
    const res = axios.get(noteURL, { responseType: "blob" });
    return res;
  };

  const fetchYourNoteFiles = async (note_bucket_key) => {
    //Find the realtime session id and corresponding notes id
    const noteURL = await readUserFilesURL(note_bucket_key);
    //console.log(noteURL);
    const res = await axios.get(noteURL, { responseType: "blob" });
    let htmlContent = "";
    const reader = new FileReader();
    reader.onload = function () {
      htmlContent = reader.result;
      //console.log("This is the note: ", htmlContent);
      setHtmlNoteRecent(htmlContent);
    };
    reader.readAsText(res.data);
  };

  const fetchTranscript = async (therapist_id, liveSessionId, therapy_id) => {
    // const transcriptUrl = await getTranscriptUrl(
    //   therapist_id,
    //   liveSessionId,
    //   therapy_id
    // );
    // console.log("---------------------", transcriptUrl.data);
    try {
      // const res = await axios.get(transcriptUrl.data, { responseType: "blob" });
      // let transcriptFileContent = "";
      // const reader = new FileReader();
      // reader.onload = function () {
      //   transcriptFileContent = reader.result;
      //   console.log("This is the transcript: ", transcriptFileContent);
      //   setTranscriptState(JSON.parse(transcriptFileContent));
      // };
      // reader.readAsText(res.data);

      const transcriptResponse = await getRealtimeSessionDetailsAPI(therapist_id, liveSessionId);
      console.log("This is the transcriptResponse: ", transcriptResponse)
      setTranscriptState(transcriptResponse.data.transcript);
    } catch (err) {
      console.log("Some error occured: ", err);
      setTranscriptState([]);
    }
  };

  const fetchProgressReport = async (note) => {
    const res = await read_report(therapy_id, note.note_id);
    setHtmlNoteRecent("");
    if (res["therapy_note"][0]) {
      await fetchYourNoteFiles(res["therapy_note"][0]["note_bucket_key"]);
    }

    const soap = {
      subjective: res["soap"]?.["subjective"],
      objective: res["soap"]?.["objective"],
      assessment: res["soap"]?.["assessment"],
      plan: res["soap"]?.["plan"],
      summary: res["soap"]?.["summary"],
    };
    const soap_suggestion = {
      subjective:
        JSON.parse(res["soap"]?.["subjective_suggestions"] ?? "[]")
          .suggestions ||
        JSON.parse(res["soap"]?.["subjective_suggestions"] ?? "[]"),
      objective:
        JSON.parse(res["soap"]?.["objective_suggestions"] ?? "[]")
          .suggestions ||
        JSON.parse(res["soap"]?.["objective_suggestions"] ?? "[]"),
      assessment:
        JSON.parse(res["soap"]?.["assessment_suggestions"] ?? "[]")
          .suggestions ||
        JSON.parse(res["soap"]?.["assessment_suggestions"] ?? "[]"),
      plan:
        JSON.parse(res["soap"]?.["plan_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["soap"]?.["plan_suggestions"] ?? "[]"),
    };

    const dap = {
      data: res["dap"]?.["data"],
      assessment: res["dap"]?.["assessment"],
      plan: res["dap"]?.["plan"],
      summary: res["dap"]?.["summary"],
    };
    const dap_suggestion = {
      data:
        JSON.parse(res["dap"]?.["data_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["dap"]?.["data_suggestions"] ?? "[]"),
      assessment:
        JSON.parse(res["dap"]?.["assessment_suggestions"] ?? "[]")
          .suggestions ||
        JSON.parse(res["dap"]?.["assessment_suggestions"] ?? "[]"),
      plan:
        JSON.parse(res["dap"]?.["plan_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["dap"]?.["plan_suggestions"] ?? "[]"),
    };

    const birp = {
      behavior: res["birp"]?.["behavior"],
      intensity: res["birp"]?.["intensity"],
      response: res["birp"]?.["response"],
      plan: res["birp"]?.["plan"],
      summary: res["birp"]?.["summary"],
    };
    const birp_suggestion = {
      behavior:
        JSON.parse(res["birp"]?.["behavior_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["birp"]?.["behavior_suggestions"] ?? "[]"),
      intensity:
        JSON.parse(res["birp"]?.["intensity_suggestions"] ?? "[]")
          .suggestions ||
        JSON.parse(res["birp"]?.["intensity_suggestions"] ?? "[]"),
      response:
        JSON.parse(res["birp"]?.["response_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["birp"]?.["response_suggestions"] ?? "[]"),
      plan:
        JSON.parse(res["birp"]?.["plan_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["birp"]?.["plan_suggestions"] ?? "[]"),
    };

    const girp = {
      goals: res["girp"]?.["goals"],
      interventions: res["girp"]?.["interventions"],
      response: res["girp"]?.["response"],
      plan: res["girp"]?.["plan"],
      summary: res["girp"]?.["summary"],
    };
    const girp_suggestion = {
      goals:
        JSON.parse(res["girp"]?.["goals_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["girp"]?.["goals_suggestions"] ?? "[]"),
      interventions:
        JSON.parse(res["girp"]?.["interventions_suggestions"] ?? "[]")
          .suggestions ||
        JSON.parse(res["girp"]?.["interventions_suggestions"] ?? "[]"),
      response:
        JSON.parse(res["girp"]?.["response_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["girp"]?.["response_suggestions"] ?? "[]"),
      plan:
        JSON.parse(res["girp"]?.["plan_suggestions"] ?? "[]").suggestions ||
        JSON.parse(res["girp"]?.["plan_suggestions"] ?? "[]"),
    };

    const _addedSuggestions = {
      soap: {
        subjective: JSON.parse(res["soap"]?.["subjective_suggestions"] ?? "[]")
          ?.addedSuggestions,
        objective: JSON.parse(res["soap"]?.["objective_suggestions"] ?? "[]")
          .addedSuggestions,
        assessment: JSON.parse(res["soap"]?.["assessment_suggestions"] ?? "[]")
          .addedSuggestions,
        plan: JSON.parse(res["soap"]?.["plan_suggestions"] ?? "[]")
          .addedSuggestions,
      },

      dap: {
        data: JSON.parse(res["dap"]?.["data_suggestions"] ?? "[]")
          .addedSuggestions,
        assessment: JSON.parse(res["dap"]?.["assessment_suggestions"] ?? "[]")
          .addedSuggestions,
        plan: JSON.parse(res["dap"]?.["plan_suggestions"] ?? "[]")
          .addedSuggestions,
      },

      birp: {
        behavior: JSON.parse(res["birp"]?.["behavior_suggestions"] ?? "[]")
          .addedSuggestions,
        intensity: JSON.parse(res["birp"]?.["intensity_suggestions"] ?? "[]")
          .addedSuggestions,
        response: JSON.parse(res["birp"]?.["response_suggestions"] ?? "[]")
          .addedSuggestions,
        plan: JSON.parse(res["birp"]?.["plan_suggestions"] ?? "[]")
          .addedSuggestions,
      },

      girp: {
        goals: JSON.parse(res["girp"]?.["goals_suggestions"] ?? "[]")
          .addedSuggestions,
        interventions: JSON.parse(
          res["girp"]?.["interventions_suggestions"] ?? "[]"
        ).addedSuggestions,
        response: JSON.parse(res["girp"]?.["response_suggestions"] ?? "[]")
          .addedSuggestions,
        plan: JSON.parse(res["girp"]?.["plan_suggestions"] ?? "[]")
          .addedSuggestions,
      },
    };

    setAddedSuggestions(_addedSuggestions);

    setSoapData({ ...soap });
    setSoapSuggestions({ ...soap_suggestion });

    setDapData({ ...dap });
    setDapSuggestions({ ...dap_suggestion });

    setBirpData({ ...birp });
    setBirpSuggestions({ ...birp_suggestion });

    setGirpData({ ...girp });
    setGirpSuggestions({ ...girp_suggestion });

    //onRealtimeReportModalToggle();

    //console.log(res);
    setSelectedLiveSessionId(res.soap.real_time_session_id);

    return res.soap.real_time_session_id;
  };

  const handleChangeCurrentTag = (type) => {
    setCurrentNoteTag(type);
  };

  const handleNoteClick = async (note) => {
    setCurrentNote(note);
    if (note?.note_type === "ai_progress_notes") {
      setNoteScreen("ai_progress_notes");
      setIsProgressNoteLoaded(false);
      const liveSession_id = await fetchProgressReport(note);
      await fetchTranscript(therapist_id, liveSession_id, therapy_id);
      await fetchProgressReport(note);
      setIsProgressNoteLoaded(true);
    } else {
      setNoteScreen("therapy_notes");
      fetchNoteFiles(note).then((res) => {
        setTherapyNotesData(res);
        setEditorLoading(false);
      });
    }
  };

  const createNotesFile = async () => {
    // Get presigned url
    const uploadNotes = await getNotesFileUploadUrl(therapy_id);

    // Creatng empty html document to upload
    const htmlDocument = new Blob([], {
      type: "text/html",
    });

    // Uploading to s3 using presigned url
    await uploadFiles(uploadNotes["url"], htmlDocument);

    // Name not needed when creating initially, in backend "Default" name is given
    const createdNote = await createNewNotes(
      therapy_id,
      uploadNotes.note_id,
      // name,
      uploadNotes.bucket,
      uploadNotes.key
    );
    //setNotes((prevNotes) => [createdNote, ...prevNotes]);
    return createdNote;
  };

  const handleNewNoteClick = (event) => {
    setIsNewNote(true);
    createNotesFile()
      .then((res) => {
        //console.log(res);
        setCurrentNote(res);
        setEditorLoading(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("Failed to create Therapy Notes");
        toast.error("Failed to create new note");
      });
  };

  let saveTimeout;

  const autoSave = () => {
    //console.log("Pressed", editorRef.current.getContent());
    clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      handleAutoSave();
      toast.success("Autosaved Successfully");
    }, 3000);
  };

  const updateNoteFile = async (note, isBackButtonPressed) => {
    // Get data from editor before dismounting the component
    const htmlDocument = new Blob([editorRef.current.getContent()], {
      type: "text/html",
    });
    const data = editorRef.current.getContent({ format: "text" }).trim();
    const name = truncateNoteName(data, 30);

    if (isBackButtonPressed) {
      editorRef.current = null;
      setEditorLoading(true);
    }
    // Get presigned url
    const uploadNotes = await getNotesFileUploadUrl(therapy_id, note.note_id);

    // Uploading notes to S3 bucket
    await uploadFiles(uploadNotes["url"], htmlDocument);

    // Updating notes name in database based on the content
    const updatedNote = await updateNotes(note.note_id, name);

    return updatedNote;
  };

  const handleAutoSave = async (isBackButtonPressed = false) => {
    let createdNote;
    if (editorRef.current) {
      await updateNoteFile(currentNote, isBackButtonPressed);
    }

    //setCurrentNote(updatedNote);

    // create the realtime session id here and save the noteId to DB and keep track of the realtime session id
    if (realtimeButton && transcriptArr.length !== 0) {
      if (liveSessionId === null) {
        // create the live session first
        createRealtimeSession(therapist_id, therapy_id)
          .then((res) => {
            // console.log(
            //   "Realtime Button Status: ",
            //   realtimeButton,
            //   " Transcript Length: ",
            //   transcript.length
            // );
            //console.log("result from create live Session: ", res);
            dispatch(setLiveSessionId(res.data.real_time_session_id));
            dispatch(setNoteId(res.data.progress_note_id));

            updateRealtimeSessionWithNoteId(
              therapist_id,
              res.data.real_time_session_id,
              currentNote ? currentNote.note_id : createdNote.note_id
            )
              .then((res) => {
                console.log("Created Note against realtime session", res);
              })
              .catch((err) => {
                console.log("Error Occured: ", err);
              });
          })
          .catch((err) => {
            console.log("Error Occured: ", err);
          });
      } else {
        // save against the already existing live session id
        updateRealtimeSessionWithNoteId(
          therapist_id,
          liveSessionId,
          currentNote ? currentNote.note_id : createdNote.note_id
        )
          .then((res) => {
            console.log("Created Note against realtime session", res);
          })
          .catch((err) => {
            console.log("Error Occured: ", err);
          });
      }
    }
  };

  const handleBack = async () => {
    if (currentNote.note_type === "therapy_notes") {
      let isBackButtonPressed = true;
      await handleAutoSave(isBackButtonPressed);
      setIsNewNote(false);
      setCurrentNote(null);
      setTherapyNotesData("");
      setEditorLoading(false);
      setNoteScreen("all_notes");
    } else {
      setIsNewNote(false);
      setCurrentNote(null);
      setTherapyNotesData("");
      setNoteScreen("all_notes");
    }
  };

  const NotesLoader = () => {
    return (
      <div className="notes-loader">
        <TailSpin
          height="80"
          width="80"
          color="#5f6ce1"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  const AllNotesView = () => {
    return (
      <div className="notes-side-vertical-bar">
        <div className="notes-side-vertical-bar-heading">
          <div>
            <div className="notes-tags-selector">
              <div className="notes-tags-selector-heading">Tags</div>
              <div
                className={`note-tag ${
                  currentNoteTag === "all" && "current-tag"
                }`}
                onClick={() => handleChangeCurrentTag("all")}
              >
                All Tags
              </div>
              <div
                className={`note-tag ${
                  currentNoteTag === "therapy_notes" && "current-tag"
                }`}
                onClick={() => handleChangeCurrentTag("therapy_notes")}
              >
                Therapy Notes
              </div>
              <div
                className={`note-tag ${
                  currentNoteTag === "ai_progress_notes" && "current-tag"
                }`}
                onClick={() => handleChangeCurrentTag("ai_progress_notes")}
              >
                Progress Notes
              </div>
            </div>
          </div>
          <div className="new-therapy-note-button" onClick={handleNewNoteClick}>
            New Therapy Note
          </div>
        </div>
        <div className="all-notes-container scroll-thin-vertical">
          {notesFetching ? (
            <NotesLoader />
          ) : (
            notes
              .filter((note) => {
                if (currentNoteTag === "all") return true;
                else {
                  return note.note_type === currentNoteTag;
                }
              })
              .map((note) => {
                return (
                  <div className="note-view-container" key={note.note_id}>
                    <div className="note-view-date">
                      {currentNoteTag === "all"
                        ? note.all_tags_parent_date
                        : note.parent_date}
                    </div>
                    <div
                      className="note-view-text"
                      onClick={() => handleNoteClick(note)}
                    >
                      <div>
                        {truncateNoteName(note.note_name, 200)}
                        {note.note_type === "therapy_notes" ? (
                          <div className="note-view-tag">
                            <img
                              className="note-view-tag-image"
                              src={ASSETS.therapy_note_tag}
                              alt="therapy_note_tag"
                            ></img>
                            <span className="note-view-tag-text">
                              #Therapy Notes
                            </span>
                          </div>
                        ) : (
                          <div className="note-view-tag">
                            <img
                              className="note-view-tag-image"
                              src={ASSETS.ai_note_tag}
                              alt="therapy_note_tag"
                            ></img>
                            <span className="note-view-tag-text">
                              #Progress Notes
                            </span>
                          </div>
                        )}
                        <p className="time-view">
                          Edited At: {`${convertToAmPm(new Date(`${note.updated_at} UTC`))}`}
                        </p>
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          color: "rgba(40, 40, 96, 1)",
                        }}
                      >
                        &gt;
                      </div>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    );
  };

  const EditorHeader = ({ noteType }) => {
    return (
      <div className="editor-container-header">
        <div
          className="editor-container-header-back-button"
          onClick={handleBack}
        >
          &lt;
        </div>
        <div>
          <div className="editor-container-header-heading">
            {currentNote
              ? new Date(currentNote.updated_at).toString().slice(4, 16)
              : new Date().toString().slice(4, 16)}
          </div>
          {noteType === "therapy_notes" ? (
            <div className="note-view-tag">
              <img
                className="note-view-tag-image"
                src={ASSETS.therapy_note_tag}
                alt="therapy_note_tag"
              ></img>
              <span className="note-view-tag-text">#Therapy Notes</span>
            </div>
          ) : (
            <div className="note-view-tag">
              <img
                className="note-view-tag-image"
                src={ASSETS.ai_note_tag}
                alt="therapy_note_tag"
              ></img>
              <span className="note-view-tag-text">#Progress Notes</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  // We are using TinyMCE as uncontrolled component to improve the performance
  const EditorNotesView = () => {
    return (
      <div className="editor-container">
        <EditorHeader noteType="therapy_notes" />
        {editorLoading ? (
          <NotesLoader />
        ) : (
          <Editor
            onInit={(evt, editor) => {
              editorRef.current = editor;
              if (!isNewNote && therapyNotesData) {
                console.log(therapyNotesData);
                let htmlContent = "";
                const reader = new FileReader();
                reader.onload = function () {
                  htmlContent = reader.result;
                  editorRef.current.setContent(htmlContent);
                };
                reader.readAsText(therapyNotesData.data);
              }
            }}
            // onChange={autoSave}
            onKeyPress={autoSave}
            onUndo={autoSave}
            // tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
            apiKey={config.TINYMCE_API_KEY}
            init={{
              menubar: false,
              statusbar: false,
              file_picker_types: "file image media",
              height: "600%",
              width: "100%",
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "table",
                "preview",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "image | help",
              content_css:
                "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');",
              skin: "borderless",
            }}
          />
        )}
      </div>
    );
  };

  const AINotesView = ({ addedSuggestionsProp }) => {
    const reportDropOptions = [
      { value: "soap", label: "SOAP Notes" },
      { value: "dap", label: "DAP Notes" },
      { value: "birp", label: "BIRP Notes" },
      { value: "girp", label: "GIRP Notes" },
    ];

    const [reportFormat, setReportFormat] = useState({
      value: "soap",
      label: "SOAP Notes",
    });

    const [soapReport, setSoapReport] = useState(soapData);
    const [isReportEdited, setIsReportEdited] = useState(false);
    const [buttonText, setButtonText] = useState("Edit to Save");
    const [suggestions, setSuggestions] = useState(soapSuggestions);
    const [addedSuggestions, setAddedSuggestions] =
      useState(addedSuggestionsProp);
    const [isLoading, setIsLoading] = useState(false);

    const [notesExpand, setNotesExpand] = useState(true);

    let note_id_slice = useSelector((store) => store.liveSession.note_id);
    note_id_slice =
      currentNote.note_id !== null ? currentNote.note_id : note_id_slice;

    useEffect(() => {
      //console.log("Drop down changed: ", reportFormat);
      setIsReportEdited(false);
      setButtonText("Edit to Save");
      if (reportFormat.value === "soap") {
        setSoapReport(soapData);
        setSuggestions(soapSuggestions);
        setAddedSuggestions(addedSuggestionsProp.soap || {});
      } else if (reportFormat.value === "dap") {
        setSoapReport(dapData);
        setSuggestions(dapSuggestions);
        setAddedSuggestions(addedSuggestionsProp.dap || {});
      } else if (reportFormat.value === "birp") {
        setSoapReport(birpData);
        setSuggestions(birpSuggestions);
        setAddedSuggestions(addedSuggestionsProp.birp || {});
      } else if (reportFormat.value === "girp") {
        setSoapReport(girpData);
        setSuggestions(girpSuggestions);
        setAddedSuggestions(addedSuggestionsProp.girp || {});
      }
    }, [reportFormat]);

    const capitalizeFirstLetter = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const deleteLastOccurrence = (originalString, substringToDelete) => {
      const lastIndex = originalString.lastIndexOf(substringToDelete);

      if (lastIndex !== -1) {
        const resultString =
          originalString.substring(0, lastIndex) +
          originalString.substring(lastIndex + substringToDelete.length);
        return resultString;
      }

      return originalString;
    };

    const onSaveClick = () => {
      // save the progress notes here as well once the user clicks to save it
      setIsLoading(true);
      setButtonText("Saving...");
      // setIsReportEdited(false);
      if (reportFormat.value === "soap") {
        console.log("only soap is edited");
        update_report(therapy_id, note_id_slice, "soap", {
          ...soapReport,
          subjective_suggestions: JSON.stringify({
            suggestions: suggestions.subjective,
            addedSuggestions: addedSuggestions.subjective,
          }),
          objective_suggestions: JSON.stringify({
            suggestions: suggestions.objective,
            addedSuggestions: addedSuggestions.objective,
          }),
          assessment_suggestions: JSON.stringify({
            suggestions: suggestions.assessment,
            addedSuggestions: addedSuggestions.assessment,
          }),
          plan_suggestions: JSON.stringify({
            suggestions: suggestions.plan,
            addedSuggestions: addedSuggestions.plan,
          }),
          updated_at: new Date(),
        })
          .then((res) => {
            console.log("updated soap: ", res);
            setButtonText("Success");
            setIsLoading(false);
            setIsReportEdited(false);
          })
          .catch((err) => {
            console.log("Some error occured: ", err);
            setIsLoading(false);
            setButtonText("Save to Notes");
            setIsReportEdited(false);
          });
      } else if (reportFormat.value === "dap") {
        console.log("only dap is edited");
        update_report(therapy_id, note_id_slice, "dap", {
          ...soapReport,
          data_suggestions: JSON.stringify({
            suggestions: suggestions.data,
            addedSuggestions: addedSuggestions.data,
          }),
          assessment_suggestions: JSON.stringify({
            suggestions: suggestions.assessment,
            addedSuggestions: addedSuggestions.assessment,
          }),
          plan_suggestions: JSON.stringify({
            suggestions: suggestions.plan,
            addedSuggestions: addedSuggestions.plan,
          }),
          updated_at: new Date(),
        })
          .then((res) => {
            console.log("updated dap: ", res);
            setButtonText("Success");
            setIsLoading(false);
            setIsReportEdited(false);
          })
          .catch((err) => {
            console.log("Some error occured: ", err);
            setButtonText("Save to Notes");
            setIsLoading(false);
            setIsReportEdited(false);
          });
      } else if (reportFormat.value === "birp") {
        console.log("only birp is edited");
        update_report(therapy_id, note_id_slice, "birp", {
          ...soapReport,
          behavior_suggestions: JSON.stringify({
            suggestions: suggestions.behavior,
            addedSuggestions: addedSuggestions.behavior,
          }),
          intensity_suggestions: JSON.stringify({
            suggestions: suggestions.intensity,
            addedSuggestions: addedSuggestions.intensity,
          }),
          response_suggestions: JSON.stringify({
            suggestions: suggestions.response,
            addedSuggestions: addedSuggestions.response,
          }),
          plan_suggestions: JSON.stringify({
            suggestions: suggestions.plan,
            addedSuggestions: addedSuggestions.plan,
          }),
          updated_at: new Date(),
        })
          .then((res) => {
            console.log("updated birp: ", res);
            setButtonText("Success");
            setIsLoading(false);
            setIsReportEdited(false);
          })
          .catch((err) => {
            console.log("Some error occured: ", err);
            setButtonText("Save to Notes");
            setIsLoading(false);
            setIsReportEdited(false);
          });
      } else if (reportFormat.value === "girp") {
        console.log("only girp is edited: ", soapReport);
        update_report(therapy_id, note_id_slice, "girp", {
          ...soapReport,
          goals_suggestions: JSON.stringify({
            suggestions: suggestions.goals,
            addedSuggestions: addedSuggestions.goals,
          }),
          interventions_suggestions: JSON.stringify({
            suggestions: suggestions.interventions,
            addedSuggestions: addedSuggestions.interventions,
          }),
          response_suggestions: JSON.stringify({
            suggestions: suggestions.response,
            addedSuggestions: addedSuggestions.response,
          }),
          plan_suggestions: JSON.stringify({
            suggestions: suggestions.plan,
            addedSuggestions: addedSuggestions.plan,
          }),
          updated_at: new Date(),
        })
          .then((res) => {
            console.log("updated grip: ", res);
            setButtonText("Success");
            setIsLoading(false);
            setIsReportEdited(false);
          })
          .catch((err) => {
            console.log("Some error occured: ", err);
            setButtonText("Save to Notes");
            setIsLoading(false);
            setIsReportEdited(false);
          });
      }
    };

    const ProgressNotesHeader = () => {
      return (
        <div className="ai-generated-report-header">
          <div className="ai-generated-report-header-heading">
            Ai Generated Report
          </div>
          <div className="ai-generated-report-header-subheading">
            Here is the report generated after the live session. This report has
            been modified according to your preferences pass-session. Feel free
            to enhance or append points using suggestion panel.
          </div>
        </div>
      );
    };

    const SaveButton = () => {
      return (
        <div className="ai-generated-report-save-button">
          <div
            className={!isReportEdited ? `save-button-inactive` : `save-button`}
            style={{
              pointerEvents: isLoading || !isReportEdited ? "none" : "auto",
            }}
            onClick={() => onSaveClick()}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {/* <span>Save to Notes</span> */}
              <span>{buttonText}</span>
              {isLoading && (
                <img
                  src={buttonLoader}
                  alt="loader"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      );
    };

    const ReportSelector = () => {
      //console.log("called again");
      const colorStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          width: "200px",
        }),
        option: (styles, state) => {
          console.log(state);
          return {
            ...styles,
            backgroundColor: state.isSelected ? "#5F6CE1" : "white",
            color: state.isSelected ? "white" : "black",
            //cursor: isDisabled ? "not-allowed" : "default",
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "#ABB2F280",
              color: "black",
            },
            ":active": {
              ...styles[":active"],
              backgroundColor: "#ABB2F280",
            },
            ":selected ": {
              ...styles[":selected"],
              backgroundColor: "#ABB2F280",
            },
          };
        },
      };

      return (
        <div className="ai-generated-report-selector">
          <p>Report Format:</p>
          <Select
            styles={colorStyles}
            maxMenuHeight={"200px"}
            defaultValue={reportFormat}
            options={reportDropOptions}
            onChange={setReportFormat}
          />
        </div>
      );
    };

    const Report = () => {
      return (
        <div className="ai-generated-report">
          {Object.keys(soapReport).map((item, index) => {
            return (
              <div key={index} className="ai-generated-report-section">
                <span className="ai-generated-report-section-heading">
                  {capitalizeFirstLetter(item)}
                </span>
                <textarea
                  className="ai-generated-report-section-content"
                  placeholder="type here.."
                  required
                  // defaultValue={soapReport[item]}
                  value={soapReport[item]}
                  readOnly
                  onChange={(e) => {
                    setButtonText("Save to Notes");
                    setIsReportEdited(true);
                    setSoapReport((prevState) => ({
                      ...prevState,
                      [item]: e.target.value,
                    }));
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    };

    const YourNotes = ({ style }) => {
      const textStyle = {
        color: "#282860",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
      };

      return (
        <div className="ai-generated-report-your-notes">
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span style={{ ...textStyle, marginBottom: "10px" }}>
                Your Notes
              </span>
            </div>
            <div
              style={{
                ...textStyle,
                fontWeight: 500,
                opacity: 0.7,
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              The notes you jotted down during the session.
            </div>
            <div
              style={{
                ...textStyle,
                fontWeight: 500,
                borderRadius: "5px",
                border: "1px solid rgba(40, 40, 96, 0.2)",
                overflowY: "scroll",
                height: "300px",
              }}
              dangerouslySetInnerHTML={{ __html: htmlNoteRecent }}
            ></div>
          </div>
        </div>
      );
    };

    const TranscriptView = () => {
      console.log("This should be the transcript: ", transcriptState);
      return (
        <div className={`ai-generated-report-transcript-view`}>
          <div className="transcript-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px 0px",
              }}
            >
              <span
                style={{
                  ...textStyle,
                }}
              >
                Session Transcription
              </span>
              <img
                src={ASSETS.downArrow}
                alt="your notes"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                }}
                onClick={() => {
                  setTranscriptsExpand(!transcriptsExpand);
                }}
              />
            </div>
            <div
              className={`scroll-thin-vertical collapsible ${
                transcriptsExpand ? "expanded" : "collapsed"
              }`}
              style={{
                ...textStyle,
                overflow: transcriptsExpand ? "auto" : "hidden",
                fontWeight: 500,
                // border: "1px solid rgba(0,0,0,0.2)",
              }}
            >
              {transcriptState.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color:
                          item.role.toLowerCase() === "client"
                            ? "#EC7440"
                            : "#282860",
                      }}
                    >
                      {item.role.toLowerCase() === "therapist"
                        ? "You"
                        : capitalizeFirstLetter(item.role)}
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "20px",
                      }}
                    >
                      {item.content}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      );
    };

    const SuggestionPanel = () => {
      return (
        <div className="ai-generated-report-suggestion-panel">
          <div className="ai-generated-report-suggestion-panel-heading">
            Suggestion Panel
          </div>
          <div>
            {Object.entries(suggestions).map((key, index) => {
              console.log(suggestions);
              return (
                <>
                  <p
                    key={index}
                    className="ai-generated-report-suggestion-panel-subheading"
                  >
                    {capitalizeFirstLetter(key[0])}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {suggestions[key[0]].map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            padding: "10px",
                            borderRadius: "10px",
                            background: addedSuggestions[key[0]]?.includes(
                              item.topic_name
                            )
                              ? "linear-gradient(0deg, rgba(236, 116, 64, 0.35) 0%, rgba(236, 116, 64, 0.35) 100%), #FFF"
                              : "#C9CEFA",
                          }}
                          onClick={() => {
                            const clickedTopic = item.topic_name;
                            const isAlreadyAdded =
                              addedSuggestions[key[0]]?.includes(clickedTopic);
                            setIsReportEdited(true);
                            setButtonText("Save to Notes");

                            if (isAlreadyAdded) {
                              const newAddedSuggestions = addedSuggestions[
                                key[0]
                              ].filter(
                                (suggestion) => suggestion !== clickedTopic
                              );
                              const newSoapReportString = deleteLastOccurrence(
                                soapReport[key[0]],
                                item.text
                              );

                              setSoapReport((prevState) => ({
                                ...prevState,
                                [key[0]]: newSoapReportString,
                              }));

                              setAddedSuggestions((prevState) => ({
                                ...prevState,
                                [key[0]]: newAddedSuggestions,
                              }));
                            } else {
                              setSoapReport((prevState) => ({
                                ...prevState,
                                [key[0]]: prevState[key[0]] + " " + item.text,
                              }));

                              setAddedSuggestions((prevState) => ({
                                ...prevState,
                                [key[0]]: [
                                  ...(prevState[key[0]] || []),
                                  clickedTopic,
                                ],
                              }));
                            }
                          }}
                        >
                          {addedSuggestions[key[0]]?.includes(
                            item.topic_name
                          ) ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "14px",
                                width: "14px",
                                marginRight: "5px",
                              }}
                            >
                              <img src={ASSETS.removeIconBlack} alt="remove" />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "14px",
                                width: "14px",
                                marginRight: "5px",
                              }}
                            >
                              <img src={ASSETS.addSignBlue} alt="add" />
                            </div>
                          )}
                          {item.topic_name}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      );
    };

    const ProgressNotes = () => {
      return (
        <div className="report-container">
          <div className="report-container-fixed">
            <ProgressNotesHeader />
            <ReportSelector />
            <SaveButton />
          </div>
          <div className="report-container-scrollable">
            <Report />
            <div>
              <YourNotes />
              <TranscriptView />
              <SuggestionPanel />
            </div>
          </div>

          {/* <TranscriptView /> */}
        </div>
      );
    };

    return (
      <div className="ai-notes-container">
        <EditorHeader noteType="ai_progress_notes" />
        {isProgressNoteLoaded ? <ProgressNotes /> : <NotesLoader />}
      </div>
    );
  };

  return (
    <div className="notes-container">
      {noteScreen === "ai_progress_notes" ? (
        <AINotesView addedSuggestionsProp={addedSuggestions} />
      ) : noteScreen === "therapy_notes" || isNewNote ? (
        EditorNotesView()
      ) : noteScreen === "all_notes" ? (
        <AllNotesView />
      ) : null}
    </div>
  );
};

export default NotesTabPanel;
