import { useDebugValue, useEffect, useState } from "react";
import ASSETS from "../../../../assets/Assets";
import "./clientDetailsContainer.css";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAuthenticator } from "@aws-amplify/ui-react";
import InviteClientByTherapistModal from "../../../../components/inviteClientByTherapistModal/InviteClientByTherapistModal";
import { useSelector } from "react-redux";
import {
  deleteTherapyNoteById,
  getPatientById,
  getTherapyConcernsByTherapyId,
  getTherapyNotesByTherapyId,
  postTherapyNote,
  updateTherapyConcernsByTherapyId,
  updateTherapyNoteById,
} from "../../../../api/therapist";
import { readUserFilesURL } from "../../../../api/s3";
import { Button } from "@mui/material";
import { format, parse, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import {
  AiFillCaretRight,
  AiFillCaretDown,
  AiFillAccountBook,
} from "react-icons/ai";
import AddSessionModal from "../addSessionModal/AddSessionModal";
import { Auth } from "aws-amplify";
import { LoadingButton } from "@mui/lab";
import { generateTranscriptTextFromArray } from "./RealtimePanel/TranscriptPanelUtls";

const ClientConcernsModal = function ({
  show,
  onClose,
  allConcerns,
  setAllConcerns,
}) {
  console.log(allConcerns);
  let [allConcernStatus, setAllConcernStatus] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const patient_id = queryParameters.get("patient_id");
  const therapy_id = queryParameters.get("therapy_id");

  useEffect(() => {
    setAllConcernStatus(allConcerns);
  }, [allConcerns]);

  function onCheckBoxTick(event, idx) {
    console.log(allConcernStatus);
    setAllConcernStatus((prevAllConcernStatus) => {
      console.log(prevAllConcernStatus);
      let newAllConcernStatus = prevAllConcernStatus.map((element, i) => {
        if (i === idx) {
          if (event.target.checked) {
            element.status = "1";
          } else {
            element.status = "0";
          }
        }
        return element;
      });
      console.log(newAllConcernStatus);
      return newAllConcernStatus;
    });
  }

  function onSaveButtonClick() {
    updateTherapyConcernsByTherapyId(allConcernStatus, therapy_id).then(
      (res) => {
        setAllConcerns(allConcernStatus);
        onClose();
      }
    );
  }

  if (!show) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content add-client-manually-modal-content client-concerns-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={onClose}
          />
        </span>
        <div className="modal-title">
          <div className="modal-heading">Add Concerns</div>
        </div>
        <div className="modal-body add-client-modal-manually">
          <div className="row concerns-section-header">
            <div className="concerns-section-heading">
              Pick the concerns your client is facing to keep a record of their
              concerns
            </div>
            {/* <div className="add-new-text">Add New</div> */}
          </div>
        </div>
        <div className="health-concerns-heading">Physical Health Concerns:</div>
        <div className="concerns-checkbox-container">
          {allConcernStatus.map((element, index) => {
            return (
              <div key={element.concern_id}>
                <input
                  type="checkbox"
                  checked={element.status == "1" ? true : false}
                  onChange={(event) => onCheckBoxTick(event, index)}
                />
                <div className="health-concerns">{element.concern}</div>
              </div>
            );
          })}
        </div>
        <div className="concerns-modal-save-button" onClick={onSaveButtonClick}>
          Save
        </div>
      </div>
    </div>
  );
};

const ClientDetailsSubHeading = function ({
  subheading,
  showConcerns,
  setShowConcerns,
}) {
  return (
    <div className="client-details-subheading-container">
      {showConcerns && (
        <div onClick={() => setShowConcerns((showConcerns) => !showConcerns)}>
          <AiFillCaretDown />
        </div>
      )}
      {!showConcerns && (
        <div onClick={() => setShowConcerns((showConcerns) => !showConcerns)}>
          <AiFillCaretRight />
        </div>
      )}
      <div className="client-details-subheading">{subheading}</div>
    </div>
  );
};

const ClientConcernsContainer = function ({ showModalHandler, title }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const patient_id = queryParameters.get("patient_id");
  const therapy_id = queryParameters.get("therapy_id");
  const therapist_id = useSelector((store) => store.therapist.therapist_id);

  let [allConcernStatus, setAllConcernStatus] = useState([]);
  let [showClientConcernsModal, setShowClientConcernsModal] = useState(false);
  const [showSelectedConcerns, setShowSelectedConcerns] = useState(true);

  function setClientConcernsModalShow() {
    setShowClientConcernsModal(true);
  }

  function setClientConcernsModalClose() {
    setShowClientConcernsModal(false);
  }

  useEffect(() => {
    getTherapyConcernsByTherapyId(therapy_id).then((res) => {
      setAllConcernStatus(res.all_concerns_status);
    });
  }, []);

  return (
    <div>
      <ClientConcernsModal
        show={showClientConcernsModal}
        onClose={setClientConcernsModalClose}
        allConcerns={allConcernStatus}
        setAllConcerns={setAllConcernStatus}
      />
      <div className="client-concerns-container">
        <ClientDetailsSubHeading
          subheading="Concerns"
          showConcerns={showSelectedConcerns}
          setShowConcerns={setShowSelectedConcerns}
        />
        <img
          src={ASSETS.addSignBlue}
          onClick={setShowClientConcernsModal}
          style={{ cursor: "pointer" }}
          alt="add concerns"
        />
      </div>
      {showSelectedConcerns && (
        <div className="selected-concerns-container">
          {allConcernStatus.length == 0 ||
            (allConcernStatus.filter(({ status }) => status == "1").length ===
              0 && (
              <div style={{ textAlign: "center", fontStyle: "italic" }}>
                No Concerns selected
              </div>
            ))}
          {allConcernStatus
            .filter(({ status }) => status == "1")
            .map(({ concern_id, concern, status }) => {
              return (
                <div key={concern_id} className="selected-concern-tile">
                  <div className="selected-concern-tile-text">{concern}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const ClientNotesContainer = function () {
  const queryParameters = new URLSearchParams(window.location.search);
  const therapy_id = queryParameters.get("therapy_id");
  let [storedNotes, setStoredNotes] = useState([]);
  const [showSelectedNotesConcerns, setShowSelectedNotesConcerns] =
    useState(true);

  console.log(storedNotes);

  useEffect(() => {
    getTherapyNotesByTherapyId(therapy_id).then((res) => {
      setStoredNotes(res.concern_notes);
    });
  }, []);

  const NoteInputField = function ({ note_id, note, created_at, index }) {
    const [loadingSave, setLoadingSave] = useState(false);
    function deleteButtonClick(idx) {
      deleteTherapyNoteById(note_id).then((res) => {
        setStoredNotes((prevNotes) =>
          prevNotes.filter((_, index) => index !== idx)
        );
      });
    }

    function saveButtonClick(idx) {
      setLoadingSave(true);
      if (note_id.length === 0) {
        // new note
        console.log("NEW NOTE");
        postTherapyNote(therapy_id, note)
          .then((res) => {
            console.log(res.concern_note_id);
            setStoredNotes((prevNotes) => {
              let newNotes = prevNotes.map((element, index) => {
                if (idx == index) {
                  element.concern_note_id = res.concern_note_id;
                  element.note = note;
                }
                return element;
              });
              return newNotes;
            });
          })
          .finally(() => {
            setLoadingSave(false);
          });
      } else {
        console.log("UPDATE NOTE");
        updateTherapyNoteById(note_id, note)
          .then((res) => {
            setStoredNotes((prevNotes) => {
              prevNotes = prevNotes.map((element, idx) => {
                if (element.concern_note_id === note_id) {
                  element.note = note;
                }
                return element;
              });
              return prevNotes;
            });
          })
          .finally(() => {
            setLoadingSave(false);
          });
      }
    }

    function onValueChange(text) {
      note = text;
    }

    return (
      <div className="note-input-container" key={note_id}>
        <textarea
          className="notes-textarea"
          defaultValue={note}
          onChange={(e) => onValueChange(e.target.value)}
          maxLength="1024"
          rows="4"
        ></textarea>
        <div className="date-delete-container">
          <div className="note-date-container">
            {created_at instanceof Date
              ? format(created_at, "Pp")
              : format(
                  parseISO(mysqlDatetimeToJavascriptDatetime(created_at)),
                  "Pp"
                )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "ceneter",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <DeleteIcon
              sx={{ color: "#282860", opacity: "0.5", cursor: "pointer" }}
              onClick={() => deleteButtonClick(index)}
            />
            <LoadingButton
              loading={loadingSave}
              variant="outlined"
              onClick={() => saveButtonClick(index)}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
    );
  };

  function addButtonClick() {
    setShowSelectedNotesConcerns(true);
    setStoredNotes((prevNotes) => [
      { note: "", created_at: new Date(), concern_note_id: "" },
      ...prevNotes,
    ]);
  }

  return (
    <div>
      <div className="client-notes-container-header">
        <ClientDetailsSubHeading
          subheading="Concern Notes"
          setShowConcerns={setShowSelectedNotesConcerns}
          showConcerns={showSelectedNotesConcerns}
        />
        <img
          src={ASSETS.addSignBlue}
          onClick={addButtonClick}
          style={{ cursor: "pointer" }}
          alt=""
        />
      </div>
      {showSelectedNotesConcerns && (
        <div className="client-notes-container-body">
          {storedNotes.length === 0 && (
            <div style={{ textAlign: "center", fontStyle: "italic" }}>
              No Concern Notes
            </div>
          )}
          {storedNotes.map((storedNote, index) => {
            return (
              <NoteInputField
                note={storedNote.note}
                note_id={storedNote.concern_note_id}
                created_at={storedNote.created_at}
                index={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const ClientInfoContainer = function ({ patientDetails, setPatientDetails }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const patient_id = queryParameters.get("patient_id");
  const therapy_id = queryParameters.get("therapy_id");

  let [patientImage, setPatientImage] = useState("");

  useEffect(() => {
    getPatientById(patient_id).then((res) => {
      setPatientDetails(res);
      if (!!res.profile_photo_key) {
        readUserFilesURL(res.profile_photo_key).then((data) => {
          setPatientImage(data);
        });
      } else {
        setPatientImage(
          `https://ui-avatars.com/api/?name=${res.name}&background=random&rounded=true`
        );
      }
    });
  }, []);

  const [showAddSessionModal, setShowAddSessionModal] = useState(false);
  const therapistZoomId = useSelector((store) => store.therapist.zoom_user_id);

  return patientDetails ? (
    <div>
      <div className="client-details-container">
        <div className="client-image-container">
          <img src={patientImage} alt="profile_pic" className="client-image" />
        </div>
        <div className="name-button-container">
          {showAddSessionModal && (
            <AddSessionModal
              onClose={() => setShowAddSessionModal(false)}
              patientObj={{
                name: patientDetails.name,
                email: patientDetails.email,
                patient_id: patient_id,
                therapy_id: therapy_id,
              }}
              patientPassed
            />
          )}
          {/* <div className="client-name">{patientDetails.name}</div> */}
          <div className="client-name">{patientDetails.therapy_name}</div>
          <button
            className="session-button"
            onClick={(e) => setShowAddSessionModal(true)}
            disabled={!therapistZoomId}
          >
            Add Session
          </button>
        </div>
        <div className="client-details-list">
          <div className="list-item-container">
            <div className="client-details-list-item-key">Contact Number</div>
            <div className="client-details-list-item-value">
              {patientDetails.contact_number}
            </div>
          </div>
          <div className="list-item-container">
            <div className="client-details-list-item-key">Gender</div>
            <div className="client-details-list-item-value">
              {patientDetails.gender}
            </div>
          </div>
          <div className="list-item-container">
            <div className="client-details-list-item-key">Connected Since</div>
            <div className="client-details-list-item-value">
              {new Date(patientDetails.connected_since).toLocaleDateString()}
            </div>
          </div>
          <div className="list-item-container">
            <div className="client-details-list-item-key">Email</div>
            <div className="client-details-list-item-value">
              {patientDetails.email}
            </div>
          </div>
          {/* <div className="list-item-container">
            <div className="client-details-list-item-key">Insurance</div>
            <div className="client-details-list-item-value">
              {patientDetails.is_insured === 0 ? "No" : "Yes"}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

const PreviousSessionHighlights = ({ highlghts }) => {
  const containerStyleBasic = {
    borderRadius: "24px",
    border: "1px solid rgba(40, 40, 96, 0.15)",
    background: "#fff",
    boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
    padding: "20px 20px",
    height: "100%",
  };

  return (
    <>
      <span
        style={{
          color: "#282860",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 500,
          letterSpacing: "0.32px",
          lineHeight: "16px",
        }}
      >
        Previous Session’s Highlights
      </span>
      <div style={{ ...containerStyleBasic }}>
        {highlghts.map((item, index) => (
          <div
            style={{
              borderRadius: "6px",
              background: "#C9CEFA",
              padding: "5px 16px",
              marginBottom: "10px",
            }}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
    </>
  );
};

const ClientDetailsContainer = function ({
  onRealtimeSessionClick,
  realtimeButtonState,
}) {
  // const queryParameters = new URLSearchParams(window.location.search);
  // const patient_id = queryParameters.get("patient_id");
  const transcriptArr = useSelector((state) => state.liveSession.transcriptArr);
  const speakerLabel = useSelector((state) => state.liveSession.speakerLabel);
  const navigate = useNavigate();
  const backArrowHandler = () => navigate(-1);
  let [patientDetails, setPatientDetails] = useState(null);
  const [showInviteClientModal, setShowInviteClientModal] = useState(false);
  const [showAddClientManuallyModal, setShowAddeClientManuallyModal] =
    useState(false);

  return (
    <>
      <InviteClientByTherapistModal
        show={showInviteClientModal}
        onClose={() => setShowInviteClientModal(false)}
        setShowAddClientManuallyModal={setShowAddeClientManuallyModal}
        patientDetails={patientDetails}
      />
      <div className="back-button-details-container">
        <div className="client-chart-heading">
          <img
            src={ASSETS.backArrow}
            className="back-arrow"
            onClick={backArrowHandler}
            alt=""
          />
          <div className="heading">Client Chart</div>
        </div>
        <div className="client-details-all-list scroll-thin-vertical">
          <ClientInfoContainer
            patientDetails={patientDetails}
            setPatientDetails={setPatientDetails}
          />
          <div
            className={`realtime-session-button-start ${
              !realtimeButtonState && "realtime-session-button-end"
            }`}
            onClick={() => {
              onRealtimeSessionClick(
                generateTranscriptTextFromArray(transcriptArr, speakerLabel)
              );
            }}
          >
            <img
              src={ASSETS.chatMic}
              alt="Realtime Session"
              className="realtime-button-mic-icon"
            />
            {realtimeButtonState
              ? "Start Realtime Session"
              : "End Realtime Session"}
          </div>
          {/* {!realtimeButtonState && (
            <PreviousSessionHighlights
              highlghts={[
                "Client shared an achievement",
                "Client exhibited signs of emotional resistance",
                "Expressing Uncertainty",
                "Shift in Emotional Tone",
                "Emotional reaction to a traumatic event",
                "Client shared about a traumatic past about her childhood and her parents",
              ]}
            />
          )} */}
          {!patientDetails?.verified ? (
            <div
              className="invite-client-button"
              onClick={() => setShowInviteClientModal(true)}
            >
              Invite Client
            </div>
          ) : (
            <div className="invite-client-already-sent-button">
              Invitation Already Sent
            </div>
          )}
          <ClientConcernsContainer />
          <ClientNotesContainer />
        </div>
      </div>
    </>
  );
};

export default ClientDetailsContainer;
