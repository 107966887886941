import React from 'react'

function TeleHealthSettings() {
  return (
    <>
      <div>Telehealth Settings</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          fontWeight: '500',
        }}
      >
        Under Development
      </div>
    </>
  )
}

export default TeleHealthSettings
