import { useState, useEffect, useRef } from "react";
import ASSETS from "../../../../../assets/Assets";
import "./RealtimePanel.css";
import { useSelector } from "react-redux";
import SentimentChart from "./SentimentChart";
import { useDispatch } from "react-redux";
import {
  clearTranscript,
  setTranscript,
  setSpeakerLabel,
} from "../../../../../store/liveSession/liveSessionSlice";

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  padding: "30px",
  height: "100%",
};

const flexCenter = {
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
};

const headerTextStyle = {
  color: "#282860",
  fontFamily: "Roboto",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "22px",
};

const closeButtonStyle = {
  cursor: "pointer",
  userSelect: "none",
};

const therapistChatStyle = {
  borderRadius: "16px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #F1F1F1",
  padding: "10px 20px",
  width: "fit-content",
  marginLeft: "auto",
  maxWidth: "70%",
};

const patientChatStyle = {
  borderRadius: "16px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #F9E3AE",
  padding: "10px 20px",
  width: "fit-content",
  maxWidth: "70%",
};

// const parseConversationString = (inputString) => {
//   const lines = inputString.split("\n").filter((line) => line.trim() !== "");

//   const conversationArray = lines
//     .map((line) => {
//       const matches = line.match(/Speaker (\d+): (.+)/);
//       if (matches && matches.length === 3) {
//         const speaker = `Speaker ${matches[1]}`;
//         const content = matches[2];
//         return { speaker, content };
//       } else {
//         return null;
//       }
//     })
//     .filter((item) => item !== null);

//   return conversationArray;
// };

// const _parseConversationString = (inputString) => {
//   const lines = inputString.split("\n").filter((line) => line.trim() !== "");

//   const conversationArray = lines
//     .map((line) => {
//       // Match "therapist:" or "client:" followed by the content
//       const matches = line.match(/(therapist|client): (.+)/i);
//       if (matches && matches.length === 3) {
//         const speaker = matches[1]; // "therapist" or "client"
//         const content = matches[2];
//         return { speaker, content };
//       } else {
//         return null;
//       }
//     })
//     .filter((item) => item !== null);

//   return conversationArray;
// };
// let targetString = "This is a string 1 and string 3 for the client and therapist.";
// let replacements = {
//     client: ["string 2", "string 1"],
//     therapist: ["string 3", "string 4"]
// };

// let result = replaceMultipleStrings(targetString, replacements);

// const replaceMultipleStrings = (targetString, replacements) => {
//   for (let role in replacements) {
//     if (replacements.hasOwnProperty(role)) {
//       replacements[role].forEach((oldString) => {
//         let newString = role; // Replace this with your desired replacement
//         targetString = targetString.split(oldString).join(newString);
//       });
//     }
//   }
//   return targetString;
// };

const TranscriptPanel = ({ onPanelClose }) => {
  const dispatch = useDispatch();
  const transcriptsEndRef = useRef(null);

  // const transcript = useSelector((state) => state.liveSession.transcript);
  // const speakerLabel = useSelector((state) => state.liveSession.speakerLabel);
  // const therapist_id = useSelector((state) => state.therapist.therapist_id);
  const transcriptArr = useSelector((store) => store.liveSession.transcriptArr);
  const speakerLabel = useSelector((store) => store.liveSession.speakerLabel);
  const scrollToBottom = () => {
    transcriptsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    // console.log("---- speaker label ----- ", speakerLabel)
    // console.log("---- modified transcript ----- ", replaceMultipleStrings(transcript, speakerLabel))
    // console.log("formatted transcript: ---", _parseConversationString(replaceMultipleStrings(transcript, speakerLabel)))
    // console.log("formatted transcript: ---", parseConversationString(replaceMultipleStrings(transcript, speakerLabel)))
    // if (transcript.length !== 0) {
    //   if (speakerLabel.hasOwnProperty("client")) {
    //     setTranscriptArr(
    //       _parseConversationString(
    //         replaceMultipleStrings(transcript, speakerLabel)
    //       )
    //     );
    //   } else {
    //     setTranscriptArr(
    //       parseConversationString(
    //         replaceMultipleStrings(transcript, speakerLabel)
    //       )
    //     );
    //   }
    // }
  }, [transcriptArr]);

  // set the diarized labels from here in a slice and then transform the headings based on this labels
  return (
    <div
      style={{
        ...containerStyleBasic,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div id="header" style={{ ...flexCenter }}>
        <span
          style={{
            ...headerTextStyle,
          }}
        >
          Live Transcription
        </span>
        <img
          src={ASSETS.modalCloseIcon}
          alt="Close Icon"
          style={{ ...closeButtonStyle }}
          onClick={onPanelClose}
        />
      </div>
      <div
        style={{
          flex: " 1 1 auto",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <pre
          className="scroll-thin-vertical"
          style={{
            ...containerStyleBasic,
            whiteSpace: "pre-wrap",
            // marginTop: "20px",
            overflowX: "auto",
            contain: "strict",
            height: "unset",
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          {/* {transcript} */}
          {transcriptArr.map((item, index) => {
            if (
              speakerLabel?.therapist?.some((label) => label === item.speaker)
            ) {
              return (
                <div style={{ ...therapistChatStyle }}>
                  <p
                    style={{
                      color: "#282860",
                      margin: 0,
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    You
                  </p>
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              );
            } else {
              const speaker = speakerLabel?.client?.some(
                (label) => label === item.speaker
              )
                ? "client"
                : item.speaker;
              return (
                <div style={{ ...patientChatStyle }}>
                  <p
                    style={{
                      color: "#282860",
                      margin: 0,
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {speaker.charAt(0).toUpperCase() + speaker.slice(1)}
                  </p>
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              );
            }
          })}
          <div ref={transcriptsEndRef} />
        </pre>
        <div
          className="sentiment-transcript-chart"
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div id="header" style={{ ...flexCenter }}>
            <span
              style={{
                ...headerTextStyle,
              }}
            >
              Client Sentiment
            </span>
          </div>
          <SentimentChart />
        </div>
      </div>
    </div>
  );
};

export default TranscriptPanel;
