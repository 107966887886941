export const replaceMultipleStrings = (targetString, replacements) => {
  for (let role in replacements) {
    if (replacements.hasOwnProperty(role)) {
      replacements[role].forEach((oldString) => {
        let newString = role; // Replace this with your desired replacement
        targetString = targetString.split(oldString).join(newString);
      });
    }
  }
  return targetString;
};

export const aggregateTimestamps = (inputString) => {
  var timestampPattern = /\{(\d+\.\d+),(\d+\.\d+)\}/g;
  var matches = inputString.match(timestampPattern);

  if (!matches) {
    return inputString;
  }

  var startValues = matches.map((match) =>
    parseFloat(match.match(/\{(\d+\.\d+),(\d+\.\d+)\}/)[1])
  );
  var endValues = matches.map((match) =>
    parseFloat(match.match(/\{(\d+\.\d+),(\d+\.\d+)\}/)[2])
  );

  var minStart = Math.min(...startValues);
  var maxEnd = Math.max(...endValues);

  var aggregatedString = inputString.replace(
    timestampPattern,
    "{" + minStart + "," + maxEnd + "}"
  );

  aggregatedString = aggregatedString.replace(
    /\{(\d+\.\d+),(\d+\.\d+)\}(?=.*\{(\d+\.\d+),(\d+\.\d+)\})/g,
    ""
  );

  return aggregatedString;
};

export const stripTimestampFromTranscript = (inputString) => {
  const timestampPattern = /\{\d+(\.\d+)?(,\d+(\.\d+)?)*\}/g;
  const cleanString = inputString.replace(timestampPattern, "");
  return cleanString.trim();
};

export const parseConversationString = (inputString) => {
  const lines = inputString.split("\n").filter((line) => line.trim() !== "");

  const conversationArray = lines
    .map((line) => {
      const matches = line.match(/Speaker (\d+): (.+)/);
      if (matches && matches.length === 3) {
        const speaker = `Speaker ${matches[1]}`;
        const content = matches[2];
        return { speaker, content };
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);

  return conversationArray;
};

export const _parseConversationString = (inputString) => {
  const lines = inputString.split("\n").filter((line) => line.trim() !== "");

  const conversationArray = lines
    .map((line) => {
      // Match "therapist:" or "client:" followed by the content
      const matches = line.match(/(therapist|client): (.+)/i);
      if (matches && matches.length === 3) {
        const speaker = matches[1]; // "therapist" or "client"
        const content = matches[2];
        return { speaker, content };
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);

  return conversationArray;
};

export const generateTranscriptTextFromArray = (
  transcriptArr,
  speakerLabel = null
) => {
  let vttContent = "";
  transcriptArr.forEach((item, index) => {
    const startTime = item.start;
    const endTime = item.end;
    let speaker = item.speaker;
    if (speakerLabel && speakerLabel?.client && speakerLabel?.therapist) {
      speaker = speakerLabel.client.some((label) => label === speaker)
        ? "client"
        : speakerLabel.therapist.some((label) => label === speaker)
        ? "therapist"
        : speaker;
    }
    vttContent += `${speaker}: ${item.content}\n`;
  });
  return vttContent;
};

export const convertTranscriptArrForSaving = (transcriptArr, speakerLabel) => {
  const arr = transcriptArr.map((item) => {
    let speaker = item.speaker;
    if (speakerLabel && speakerLabel?.client && speakerLabel?.therapist) {
      speaker = speakerLabel.client.some((label) => label === speaker)
        ? "client"
        : speakerLabel.therapist.some((label) => label === speaker)
        ? "therapist"
        : speaker;
    }
    return { ...item, role: speaker };
  });
  return arr;
};
