import React from "react";
import "./SessionItem.css";
import ASSETS from "../../../../assets/Assets";
import { BsChevronRight } from "react-icons/bs";
import { add, format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import MeetingSession from "./MeetingSession";
import RealtimeSession from "./RealtimeSession";

function SessionItem({ session, setVideoId, setIndex }) {
  return (
    <>
      {session["type"] === "meeting" && (
        <MeetingSession
          session={session}
          setVideoId={setVideoId}
          setIndex={setIndex}
        />
      )}
      {session["type"] === "realtime" && (
        <RealtimeSession
          session={session}
          setVideoId={setVideoId}
          setIndex={setIndex}
        />
      )}
    </>
  );
}

export default SessionItem;
