import React from "react";

function RealtimeSessionGirp({ girp }) {
  return (
    <div className="realtime-session-progress-notes-wrapper">
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Summary</div>
        <div className="realtime-session-progress-notes-body">
          {girp?.summary}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Goals</div>
        <div className="realtime-session-progress-notes-body">
          {girp?.goals}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">
          Interventions
        </div>
        <div className="realtime-session-progress-notes-body">
          {girp?.interventions}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Response</div>
        <div className="realtime-session-progress-notes-body">
          {girp?.response}
        </div>
      </div>
      <div className="realtime-session-progress-notes-section">
        <div className="realtime-session-progress-notes-heading">Plan</div>
        <div className="realtime-session-progress-notes-body">{girp?.plan}</div>
      </div>
    </div>
  );
}

export default RealtimeSessionGirp;
