import React, { useState } from "react";
import "./AddAssessment.css";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";

import { createAssessmentFrequencyAPI } from "../../../../../../../api/assessments";

const AssessmentSelector = ({
  assessment,
  handleAssessmentChange,
  frequencyData,
}) => {
  return (
    <div className="assessment-selector">
      <FormControl sx={{ m: 1, minWidth: 200 }} id="form-control">
        <InputLabel
          id="assessment-selector-label"
          sx={{ fontFamily: "Poppins", color: "#282860", fontSize: "12px" }}
        >
          Add Assessment
        </InputLabel>
        <Select
          labelId="assessment-selector-label"
          id="choose-assessment-selector"
          value={assessment}
          label="Add Assessment"
          onChange={handleAssessmentChange}
          sx={{
            background: "rgba(221, 226, 233, 0.15)",
            outline: "none",
            border: "none",
            borderRadius: "8px",
          }}
        >
          <MenuItem value={"PHQ9"} disabled={frequencyData["isPHQ9Present"]}>
            PHQ 9
          </MenuItem>
          <MenuItem value={"GAD7"} disabled={frequencyData["isGAD7Present"]}>
            GAD 7
          </MenuItem>
          <MenuItem
            value={"AI CHECK IN"}
            disabled={frequencyData["isAICheckInPresent"]}
          >
            Ai Check in Status
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const FrequencySelector = ({ frequency, handleFrequencyChange }) => {
  return (
    <div className="assessment-frequency-selector">
      <FormControl sx={{ m: 1, minWidth: 200 }} id="form-control">
        <InputLabel
          id="assessment-frequency-selector-label"
          sx={{ fontFamily: "Poppins", color: "#282860", fontSize: "12px" }}
        >
          Frequency
        </InputLabel>
        <Select
          labelId="assessment-frequency-selector-label"
          id="choose-assessment-frequency-selector"
          value={frequency}
          label="Add Assessment"
          onChange={handleFrequencyChange}
          sx={{
            background: "rgba(221, 226, 233, 0.15)",
            outline: "none",
            border: "none",
            borderRadius: "8px",
          }}
        >
          <MenuItem value={"DAILY"}>DAILY</MenuItem>
          <MenuItem value={"WEEKLY"}>WEEKLY</MenuItem>
          <MenuItem value={"BIWEEKLY"}>BIWEEKLY</MenuItem>
          <MenuItem value={"MONTHLY"}>MONTHLY</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const AddAssessmentButton = ({ handleAddAssessment, frequency }) => {
  return (
    <div className="add-assessment-button">
      <Button
        variant="contained"
        onClick={handleAddAssessment}
        disabled={frequency === "" ? true : false}
        sx={{
          background: "rgba(95, 108, 225, 1)",
          color: "white",
          borderRadius: "8px",
          textTransform: "capitalize",
          width: "200px",
          fontSize: "15px",
          marginLeft: "20px",
        }}
      >
        Send Assessment
      </Button>
    </div>
  );
};

const AssessmentFrequencyList = ({
  frequencyData,
  handleFrequencyChangeCallback,
  handleFrequencyDeleteCallback,
}) => {
  const DeleteIconButton = ({
    assessmentType,
    handleFrequencyDeleteCallback,
  }) => {
    return (
      <IconButton
        aria-label="delete"
        onClick={() => handleFrequencyDeleteCallback(assessmentType)}
      >
        <DeleteIcon sx={{ color: "rgba(236, 116, 64, 1)" }} />
      </IconButton>
    );
  };

  const UpdateFrequencySelector = ({
    assessmentType,
    frequency,
    handleFrequencyChangeCallback,
  }) => {
    return (
      <div className="update-frequency-selector">
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
          }}
          id="form-control"
          variant="standard"
        >
          <Select
            labelId="assessment-frequency-selector-label"
            id="choose-assessment-frequency-selector"
            value={frequency}
            label="Frequency"
            onChange={(e) => handleFrequencyChangeCallback(e, assessmentType)}
            disableUnderline
            sx={{
              // background: "rgba(221, 226, 233, 0.15)",
              outline: "none",
              border: "none",
              borderRadius: "8px",
              boxShadow: "none",
              color: "rgba(95, 108, 225, 1)",
            }}
          >
            <MenuItem value={"DAILY"}>DAILY</MenuItem>
            <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
            <MenuItem value={"BIWEEKLY"}>Biweekly</MenuItem>
            <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  const assessmentTypeLabel = {
    GAD7: "GAD 7",
    PHQ9: "PHQ 9",
    "AI CHECK IN": "Ai Check-in Status",
  };

  return (
    <div className="assessment-frequency-list-item-container">
      {frequencyData["frequency"].map((item) => {
        return (
          <div className="assessment-frequency-list-item">
            <div className="assessment-frequency-list-item-assessment-type">
              {assessmentTypeLabel[item["assessmentType"]]}
            </div>
            <div className="assessment-frequency-list-item-frequency">
              <div>Frequency:</div>
              <UpdateFrequencySelector
                assessmentType={item["assessmentType"]}
                frequency={item["frequency"]}
                handleFrequencyChangeCallback={handleFrequencyChangeCallback}
              />
            </div>
            <div className="assessment-frequency-list-item-last-taken">
              <div>Last Taken:</div>
              <div>{item["lastTaken"]}</div>
            </div>
            <div className="assessment-frequency-list-item-due-date">
              <div>Due Date:</div>
              <div>{item["dueDate"]}</div>
            </div>
            <DeleteIconButton
              assessmentType={item["assessmentType"]}
              handleFrequencyDeleteCallback={handleFrequencyDeleteCallback}
            />
          </div>
        );
      })}
    </div>
  );
};

const AddAssessment = ({
  frequencyData,
  handleUpdateFrequencyChangeCallback,
  handleAddFrequencyCallback,
  handleFrequencyDeleteCallback,
}) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const therapyId = queryParameters.get("therapy_id");

  const [showFrequencyButton, setShowFrequencyButton] = useState(false);
  const [assessment, setAssessment] = React.useState("");
  const [frequency, setFrequency] = React.useState("");

  const handleAssessmentChange = (event) => {
    setAssessment(event.target.value);
    setShowFrequencyButton(true);
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const showFrequencyButtonCallback = () => {
    setShowFrequencyButton((current) => !current);
  };

  const handleAddAssessment = () => {
    // call the api
    let startDate = new Date().toISOString().slice(0, 19).replace("T", " ");
    let endDate = "";
    createAssessmentFrequencyAPI(
      therapyId,
      assessment,
      frequency,
      startDate,
      endDate
    )
      .then((res) => {
        console.log(res);
        toast.success("Assessment created");
        // update frequency state
        handleAddFrequencyCallback();
        setAssessment("");
        setFrequency("");
        setShowFrequencyButton((current) => !current);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to add assessment");
        alert("Something went wrong when creating assessment");
      });
  };

  return (
    <div className="add-assessment">
      <div className="add-assessment-button-container">
        {frequencyData.isVerified ? (
          <AssessmentSelector
            assessment={assessment}
            handleAssessmentChange={handleAssessmentChange}
            showFrequencyButtonCallback={showFrequencyButtonCallback}
            frequencyData={frequencyData}
          />
        ) : (
          <div></div>
        )}
        {!showFrequencyButton ? (
          <div></div>
        ) : (
          <div className="add-assessment-frequency-button-container">
            <FrequencySelector
              frequency={frequency}
              handleFrequencyChange={handleFrequencyChange}
              showFrequencyButtonCallback={showFrequencyButtonCallback}
            />
            <AddAssessmentButton
              handleAddAssessment={handleAddAssessment}
              frequency={frequency}
            />
          </div>
        )}
      </div>
      <AssessmentFrequencyList
        frequencyData={frequencyData}
        handleFrequencyChangeCallback={handleUpdateFrequencyChangeCallback}
        handleFrequencyDeleteCallback={handleFrequencyDeleteCallback}
      />
    </div>
  );
};

export default AddAssessment;
