import { useState, useEffect } from "react";
import ASSETS from "../../../../assets/Assets";
import {
  fetchActionSuggestions,
  getTranscriptUrl,
} from "../../../../api/realtime";
import { fetchNotesByTherapyId, read_report } from "../../../../api/notes";
import axios from "axios";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

const textStyleHeading = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.02em",
  textAlign: "left",
  color: "#282860",
};

const suggestionTitleTextStyle = {
  fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "rgba(40, 40, 96, 1)",
  opacity: 0.65,
};

const suggestionActionTextStyle = {
  ...suggestionTitleTextStyle,
  fontWeight: 500,
  fontSize: "16px",
};

const suggestionTagTextStyle = {
  ...suggestionActionTextStyle,
  fontWeight: 500,
  opacity: 0.5,
};

const suggestionContainerStyle = {
  border: "1px solid rgba(40, 40, 96, 0.15)",
  borderRadius: "16px",
  padding: "0px 20px",
};

const getPropertyIgnoreCase = (obj, propertyName) => {
  propertyName = propertyName.toLowerCase();
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key.toLowerCase() === propertyName) {
      return obj[key];
    }
  }
  return undefined;
}

const ActionSuggestionCard = ({
  actionSuggestion,
  handleToggle,
  isCollapsed,
  index,
}) => {
  return (
    <div
      style={{
        ...suggestionContainerStyle,
        marginBottom: "20px",
        padding: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ ...suggestionTitleTextStyle, padding: "0px 20px" }}>
          {getPropertyIgnoreCase(actionSuggestion, 'title')}
        </p>
        <img
          src={ASSETS.downArrow}
          alt="your notes"
          style={{
            cursor: "pointer",
            padding: "5px",
            marginRight: "10px",
          }}
          onClick={() => {
            handleToggle(index);
          }}
        />
      </div>
      <div
        style={{
          borderTop: "1px solid rgba(40, 40, 96, 0.15)",
          borderBottom: "1px solid rgba(40, 40, 96, 0.15)",
          background:
            "linear-gradient(0deg, rgba(40, 40, 96, 0.03), rgba(40, 40, 96, 0.03))",
        }}
      >
        {isCollapsed && (
          <p
            style={{
              ...suggestionActionTextStyle,
              padding: "0px 20px",
              boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
            }}
          >
            {getPropertyIgnoreCase(actionSuggestion, 'action')}
          </p>
        )}
      </div>
      <p style={{ ...suggestionTagTextStyle, padding: "0px 20px" }}>
        {getPropertyIgnoreCase(actionSuggestion, 'tag')}
      </p>
    </div>
  );
};

function ActionSuggestions() {
  const queryParameters = new URLSearchParams(window.location.search);
  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  const therapy_id = queryParameters.get("therapy_id");

  const [collapsedList, setCollapsedList] = useState([]);
  const [actionSuggestions, setActionSuggestions] = useState([]);
  const [actionSuggestionLoader, setActionSuggestionLoader] = useState(false);

  useEffect(() => {
    setActionSuggestionLoader(true);
    fetchActionSuggestions(therapy_id, therapist_id)
      .then((res) => {
        console.log("suggestions:", res.data)
        setActionSuggestions(res.data);
        setActionSuggestionLoader(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setActionSuggestionLoader(false);
      });
  }, []);

  const toggleCollapse = (index) => {
    setCollapsedList((prevList) => {
      if (prevList.includes(index)) {
        return prevList.filter((item) => item !== index);
      } else {
        return [...prevList, index];
      }
    });
  };

  const handleToggle = (index) => {
    toggleCollapse(index);
  };

  return (
    <div style={{ ...suggestionContainerStyle }}>
      <p
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "15px",
            width: "15px",
            borderRadius: "50%",
            background: "rgba(95, 108, 225, 1)",
          }}
        ></div>
        <span
          style={{
            font: "Poppins",
            fontWeight: 500,
            fontSize: "16px",
            color: "#282860",
          }}
        >
          Action Suggestions
        </span>
      </p>
      <div>
        {actionSuggestionLoader ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <ThreeDots
              height="100"
              width="100"
              color="#5F6CE1"
              radius="50"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        ) : (
          actionSuggestions.length !== 0 &&
          actionSuggestions?.map((actionSuggestion, index) => (
            <ActionSuggestionCard
              actionSuggestion={actionSuggestion}
              key={index}
              handleToggle={handleToggle}
              isCollapsed={collapsedList.includes(index)}
              index={index}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ActionSuggestions;
